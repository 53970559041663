import { removeAllLocalItem } from "@rentspree/cookie"
import get from "lodash/get"
import { ALERT_PRESET, ALERT_MESSAGE } from "libs/sweet-alert"
import { locationAssign } from "./call-window"

/* eslint-disable */
export const reloadPage = () => location.reload()
export const toPage = path => () => locationAssign(path)
export const closeModal = () => {}

export function buildSweetAlertProperties(customMessage, type = "ERROR", next = reloadPage) {
  return {
    preset: ALERT_PRESET[type],
    option: customMessage,
    promise: next
  }
}

/* eslint-disable */
export const buildErrorSweetAlertProperties = (
  error,
  customMessage,
  next = reloadPage
) => {
  switch (get(error, "status")) {
    case 401:
      return buildSweetAlertProperties(
        ALERT_MESSAGE.UNAUTHORIZED_ERROR,
        "ERROR",
        () => {
          removeAllLocalItem()
          next()
        })
    default:
      return buildSweetAlertProperties(customMessage, "ERROR", next)
  }
}
