import React from "react"
import styled from "styled-components"

import { createProperty } from "utils/style-utils"
import hexagonBg from "images/gplay-diamond.png"

export const InnerWrapper = styled.div`
  align-items: ${props => (props.alignItems ? props.alignItems : "center")};
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  padding: ${props => (props.padding ? props.padding : "55px 0")};
  position: relative;
  ${props => props.bgImg && createProperty("background", props.bgColor)};
  ${props =>
    props.bgColor && createProperty("background-color", props.bgColor)};

  @media (max-width: 767px) {
    padding: ${props => (props.mPadding ? props.mPadding : "0")};
  }
`

export const InnerWrapperHexagonBg = ({ children, ...props }) => (
  <InnerWrapper bgColor="#fff" bgImg={hexagonBg} {...props}>
    {children && children}
  </InnerWrapper>
)

export const VersionTag = styled.div`
  color: white;
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 10px;
  @media (max-width: 767px) {
    display: none;
  }
`
