import React from "react"
import styled from "styled-components"

import {
  MAIN_PANEL_HEIGHT,
  MAIN_PANEL_WIDTH,
  MAIN_PANEL_SHADOW,
  COLOR,
  NEW_MAIN_PANEL_HEIGHT
} from "styles/settings"
import ErrorMessage from "containers/error"
import { I } from "components/typography/text"
import { H1 } from "components/typography/heading"
import { OverlayLoading, StyledLoading } from "containers/loading"
import { LogoHeader } from "./logo-header"

export const StyledMainPanel = styled.div`
  background-color: white;
  box-shadow: ${MAIN_PANEL_SHADOW};
  min-height: ${props =>
    props.isNewHeight ? MAIN_PANEL_HEIGHT : NEW_MAIN_PANEL_HEIGHT};
  width: ${MAIN_PANEL_WIDTH};
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    min-height: 0px;
    flex-grow: 1;
    box-shadow: none;
  }
`

export const StyledNoPanel = styled.div`
  background: white;
`

export const StyledMainPanelBody = styled.div`
  padding: 30px;
  @media (max-width: 767px) {
    padding: 0 25px 25px;
  }
`

export const Header = styled(H1)`
  margin: 0 0 30px;
`

export const StyledFullLoading = styled(StyledLoading)`
  background: rgba(255, 255, 255, 0.8);
`

export const MainPanel = ({
  children,
  error,
  loading = {},
  location = {},
  isNoPanel = false,
  showSocial = false
}) => {
  const isNewHeight = !showSocial

  if (isNoPanel) {
    return <StyledNoPanel>{children}</StyledNoPanel>
  }

  return (
    <StyledMainPanel isNewHeight={isNewHeight}>
      {error && <ErrorMessage location={location} />}
      {loading.panel && <OverlayLoading />}
      <LogoHeader className="visible-xs" query={location.search} />
      {children}
    </StyledMainPanel>
  )
}

export const MainPanelBody = ({ children, isNoPanel }) => {
  if (isNoPanel) {
    return <>{children}</>
  }

  return <StyledMainPanelBody>{children}</StyledMainPanelBody>
}

export const FullLoading = () => (
  <MainPanel>
    <MainPanelBody>
      <StyledFullLoading>
        <I
          size="32px"
          className="fas fa-circle-notch fa-spin"
          color={COLOR.brightBlue}
        />
      </StyledFullLoading>
    </MainPanelBody>
  </MainPanel>
)

export const NewUiLoading = () => (
  <StyledFullLoading>
    <I
      size="32px"
      className="fas fa-circle-notch fa-spin"
      color={COLOR.brightBlue}
    />
  </StyledFullLoading>
)
