/*
 *
 * PageResetPassword constants
 *
 */

export const RESET_PASSWORD_CALL = "RESET_PASSWORD_CALL"
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"

export const resetPasswordCall = payload => ({
  type: RESET_PASSWORD_CALL,
  payload
})

export const resetPasswordRequest = payload => ({
  type: RESET_PASSWORD_REQUEST,
  payload
})

export const resetPasswordSuccess = payload => ({
  type: RESET_PASSWORD_SUCCESS,
  payload
})

export const resetPasswordFailed = payload => ({
  type: RESET_PASSWORD_FAILED,
  payload
})

export function getRequestPayload(data) {
  return {
    password: data.password,
    passwordConfirm: data.password
  }
}
