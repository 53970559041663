let env
if (
  typeof window !== "undefined" &&
  window.process &&
  window.process.NODE_ENV === "production"
) {
  env = window.process
} else {
  /* eslint-disable prefer-destructuring */
  env = process.env
}
const proc = env
// export default (typeof window !== "undefined" && window.process ? (window.process.NODE_ENV === "production") ? window.process : process.env : process.env || {})
// export default proc

export const NODE_ENV = proc.NODE_ENV
export const MAIN_URL = proc.MAIN_URL
export const API_URL = proc.API_URL
export const USER_API_URL = proc.USER_API_URL
export const FILE_API_URL = proc.FILE_API_URL
export const PARTNER_ADMIN_URL = proc.PARTNER_ADMIN_URL
export const DOMAIN_NAME = proc.DOMAIN_NAME
export const CDN_URL = proc.CDN_URL
export const IMAGE_PROXY_URL = proc.IMAGE_PROXY_URL
export const BASE_NAME = proc.BASE_NAME
export const FACEBOOK_APP_ID = proc.FACEBOOK_APP_ID
export const FACEBOOK_APP_VERSION = proc.FACEBOOK_APP_VERSION
export const GOOGLE_TAG_MANAGER_CONTAINER_ID =
  proc.GOOGLE_TAG_MANAGER_CONTAINER_ID
export const IDLE_TIMEOUT_MS = parseInt(proc.IDLE_TIMEOUT_MS || "30000", 10)
export const AMPLITUDE_API_KEY = proc.AMPLITUDE_API_KEY
export const AMPLITUDE_COOKIE_DOMAIN = proc.AMPLITUDE_COOKIE_DOMAIN
export const RENTALS_DOMAIN = proc.RENTALS_DOMAIN
export const CANCEL_SUBSCRIPTION_SURVICATE_ID =
  proc.CANCEL_SUBSCRIPTION_SURVICATE_ID

// default client ID dev & uat in Legacy-Rentspree
export const GOOGLE_CLIENT_ID = proc.GOOGLE_CLIENT_ID

export const ZIPLOGIX_HOW_TO_LINK_URL = proc.ZIPLOGIX_HOW_TO_LINK_URL

// ******** NOTE!!!- Don't forget to add another env in internals/env.js***************

// Sentry
export const CI_COMMIT_SHA = proc.CI_COMMIT_SHA
export const SENTRY_DSN =
  proc.SENTRY_DSN ||
  "https://2c107c6bb158422b9665ed2795f44d92@sentry.rentspree.com/3"
export const ENVIRONMENT = proc.ENVIRONMENT

// === HubSpot Environments ===
// Form info
export const HUBSPOT_PORTAL_ID = proc.HUBSPOT_PORTAL_ID
export const HUBSPOT_CCPA_REQUEST_FORM_ID = proc.HUBSPOT_CCPA_REQUEST_FORM_ID
// Info domain
export const HUBSPOT_EMAIL_UNSUBSCRIBE_URL = proc.HUBSPOT_EMAIL_UNSUBSCRIBE_URL
export const HUBSPOT_EMAIL_SIMPLE_UNSUBSCRIBE_URL =
  proc.HUBSPOT_EMAIL_SIMPLE_UNSUBSCRIBE_URL
// Request download user information on CCPA topic
export const REQUEST_PERSONAL_INFORMATION = proc.REQUEST_PERSONAL_INFORMATION
// Delete account on CCPA topic
export const HUBSPOT_DELETE_ACCOUNT_TOPIC = proc.HUBSPOT_DELETE_ACCOUNT_TOPIC
// === End of HubSpot Environments ===

// === Subscription features Environments ===
export const FREE_PLAN_FEATURES = proc.FREE_PLAN_FEATURES
export const PRO_PLAN_FEATURES = proc.PRO_PLAN_FEATURES
export const ENTERPRISE_PLAN_FEATURES = proc.ENTERPRISE_PLAN_FEATURES

export const RECOMMENDED_PLAN = proc.RECOMMENDED_PLAN

export const SURVICATE_SDK_PROVIDER_KEY = proc.SURVICATE_SDK_PROVIDER_KEY
export const STRIPE_SDK_KEY = proc.STRIPE_SDK_KEY

export const ALWAYS_SHOW_STRIKETHROUGH_SUBSCRIPTION_PRICE =
  proc.ALWAYS_SHOW_STRIKETHROUGH_SUBSCRIPTION_PRICE

export const TOGGLE_BUTTON_YEARLY_SUBTITLE = proc.TOGGLE_BUTTON_YEARLY_SUBTITLE

export const { STATSIG_SDK_KEY } = proc
export const { STATSIG_API } = proc
export const { STATSIG_ENVIRONMENT } = proc

// === End of Subscription features Environments ===

export const RESEND_NEW_EMAIL_REQUEST_THROTTLE_PERIOD = parseInt(
  proc.RESEND_NEW_EMAIL_REQUEST_THROTTLE_PERIOD || 30,
  10
)

// DataDog
export const { DATADOG_APPLICATION_ID } = proc
export const { DATADOG_CLIENT_TOKEN } = proc
export const { DATADOG_SITE } = proc
export const DATADOG_SAMPLE_RATE = parseInt(proc.DATADOG_SAMPLE_RATE, 10)
export const DATADOG_REPLAY_SAMPLE_RATE = parseInt(
  proc.DATADOG_REPLAY_SAMPLE_RATE,
  10
)
export const DATADOG_TRACE_SAMPLE_RATE = parseInt(
  proc.DATADOG_TRACE_SAMPLE_RATE,
  10
)

export const IS_REDIRECT_FROM_USER_TYPE = proc.IS_REDIRECT_FROM_USER_TYPE
export const { DD_VERSION: DATADOG_VERSION } = proc
// === Auth0 ===

export const { AUTH_PROVIDER_DOMAIN } = proc
export const { AUTH_APP_CLIENT_ID } = proc
export const { AUTH_REDIRECT_PATH } = proc
export const { AUTH_AUTHENTICATED_PATH } = proc
export const { AUTH_API_IDENTIFIER } = proc
export const { TOKEN_SELECTOR } = proc
export const { AUTH_DB_CONNECTION } = proc
export const { AUTH_APP_SCOPE } = proc
export const { AUTH_COOKIE_DOMAIN } = proc

// === Turnstile ===

export const { TURNSTILE_ENABLED } = proc
export const { TURNSTILE_SITE_KEY } = proc
export const { GOOGLE_PLACE_API_KEY } = proc
export const { GOOGLE_MAP_KEY } = proc
