import { fromJS } from "immutable"
import { ADD_TOAST, CLOSE_TOAST } from "./constants"

// Toast item: list of obj
// obj body:
//  status = <"success"|"info"|"error"> (accordingly to panel props)
//  titleMessage = string
//  bodyMessage = string

export const initialState = fromJS({ toastList: [] })

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, { type, payload }) => {
  let newState

  switch (type) {
    case ADD_TOAST:
      newState = {
        toastList: [payload]
      }
      break
    case CLOSE_TOAST: {
      const newToastList = state
        .toJS()
        .toastList.filter(toast => toast.id !== payload) // payload is toast id
      newState = {
        toastList: newToastList
      }
      break
    }
    default:
      break
  }
  return state.merge(newState)
}

export default reducer
