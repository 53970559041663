import { css } from "styled-components"
import { COLOR } from "../../styles/settings"

export const transparentBtn = css`
  background-color: transparent;
  border: 0px;
  color: ${COLOR.white};
`

export const blackTextBtn = css`
  background-color: transparent;
  border: 0px;
  &:hover {
    color: ${COLOR.lightGray};
  }
`

export const greenGradientBtn = css`
  background: linear-gradient(to right, ${COLOR.green}, ${COLOR.springGreen});
  border: 0px;
  color: ${COLOR.white};
`

export const blueGradient = css`
  background: linear-gradient(
    to right,
    ${COLOR.waterBlue},
    ${COLOR.waterBlue},
    ${COLOR.brightBlue},
    ${COLOR.skyBlue}
  );
`

export const blueGradientHovered = css`
  background: linear-gradient(
    to right,
    ${COLOR.waterBlue},
    ${COLOR.brightBlue}
  );
  border: 0px;
  color: ${COLOR.white};
`

export const facebookBtn = css`
  color: ${COLOR.white};
  background-color: ${COLOR.facebookBlue};
  font-weight: 600;
  &:hover {
    background-color: ${COLOR.darkFacebookBlue};
  }
  transition: all, 0.5s;
  transform: background-color;
`

export const googleBtn = css`
  border: solid 1px ${COLOR.lightGray};
  font-weight: 600;
  &:hover {
    background-color: ${COLOR.veryLightGray};
  }
  transition: all, 0.5s;
  transform: background-color;
`

export const socialLoginBtn = css`
  border: solid 1px ${COLOR.mediumGray};
  color: ${COLOR.lightBlack};
  font-weight: 600;
  &:hover {
    background-color: ${COLOR.veryLightGray};
  }
  transition: all, 0.5s;
  transform: background-color;
  margin-bottom: 15px;
`

export const blueText = css`
  display: inline-flex;
  padding: 0;
  height: auto;
  color: ${COLOR.brightBlue};
  &:hover {
    color: ${COLOR.waterBlue};
  }
`
export const emailBtn = css`
  border: solid 1px ${COLOR.mediumGray};
  font-weight: 600;
  &:hover {
    background-color: ${COLOR.veryLightGray};
  }
  transition: all, 0.5s;
  transform: background-color;
  margin-bottom: 15px;
`
