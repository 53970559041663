import uuidv4 from "uuid/v4"
import { ADD_TOAST, CLOSE_TOAST } from "./constants"

export const addToast = toast => {
  const id = uuidv4()
  return {
    type: ADD_TOAST,
    payload: { ...toast, id }
  }
}

export const closeToast = id => ({
  type: CLOSE_TOAST,
  payload: id
})
