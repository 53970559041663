import React from "react"
import styled from "styled-components"

export const FixedWrapper = styled.div`
  position: fixed;
  top: ${({ noneNavbar }) => (noneNavbar ? "0" : "70px")};
  left: 0;
  z-index: 99;
  width: 100%;
  height: ${({ noneNavbar }) => (noneNavbar ? "100vh" : "calc(100vh - 70px)")};
  ${({ background }) => (background ? `background : ${background};` : "")};
`

export const CenteredWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
`

export const LoadingWrapper = ({ children, noneNavbar, background }) => (
  <FixedWrapper noneNavbar={noneNavbar} background={background}>
    <CenteredWrapper>{children}</CenteredWrapper>
  </FixedWrapper>
)
