import React from "react"
import styled from "styled-components"

import { FLEX_CENTER, COLOR } from "styles/settings"
import { I } from "components/typography/text"

export const StyledLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${FLEX_CENTER};
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;
  z-index: 3;
  ${props => props.column && "flex-direction: column"};
`

export const OverlayLoading = ({
  background,
  column,
  children,
  iconMargin,
  dataTestId
}) => (
  <StyledLoading
    data-testid={dataTestId}
    style={{ background }}
    column={column}>
    <I
      size="32px"
      className="fas fa-circle-notch fa-spin"
      color={COLOR.brightBlue}
      margin={iconMargin}
    />
    {children && children}
  </StyledLoading>
)
