/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
import React from "react"
import { Route } from "react-router-dom"
import { MAIN_URL, BASE_NAME } from "env"

const OAUTH_USER = "/oauth/:userType(applicant|owner)"
const OAUTH = "/oauth"

export const PROFILE = "/profile"
const CCPA = `/ccpa`

export const ONBOARDING = "/onboarding"
export const ONBOARDING_ROUTES = [
  ONBOARDING,
  `${ONBOARDING}/profile`,
  `${ONBOARDING}/user-type`,
  `${ONBOARDING}/first-task`,
  `${ONBOARDING}/first-property`,
  `${ONBOARDING}/user-role`
]

export const ONBOARDING_TRACKER_PROPERTY = {
  [ONBOARDING_ROUTES[0]]: () => ({
    subFeature: "get_started",
    location: "onboarding_get_started"
  }),
  [ONBOARDING_ROUTES[1]]: () => ({
    subFeature: "user_profile",
    location: "onboarding_user_profile"
  }),
  [ONBOARDING_ROUTES[2]]: () => ({
    subFeature: "user_type",
    location: "onboarding_user_type"
  }),
  [ONBOARDING_ROUTES[3]]: () => ({
    subFeature: "first_task",
    location: "onboarding_first_task",
    formLocation: "onboarding_first_task_form"
  }),
  [ONBOARDING_ROUTES[4]]: () => ({
    subFeature: "first_property",
    location: "onboarding_first_property"
  }),
  [ONBOARDING_ROUTES[5]]:
    /**
     * @param {object} args
     * @param {boolean} args.skippedPropertyPage
     */
    args => ({
      subFeature: args?.skippedPropertyPage
        ? "fallback_account_role"
        : "first_property_role",
      location: args?.skippedPropertyPage
        ? "onboarding_fallback_account_role"
        : "onboarding_first_property_role"
    })
}

export const ROUTE = {
  LOGIN: "/login",
  AUTHENTICATED: "/authenticated",
  CALLBACK: "/callback",
  LOGOUT: "/logout",
  SESSION_TIMEOUT: "/session-timeout",
  SIGNUP: "/signup",
  CREATE_PASSWORD: "/create-password",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/set-password",
  RESET_PASSWORD_LEGACY: "/reset-password/:resetToken",
  LOGIN_EXIST_EMAIL: "/login/email",

  SELECT_USER_TYPE: "/user-type",
  SOCIAL_PASSWORD: "/social",
  SOCIAL_EXIST: "/login/:social",
  EDIT_PROFILE: "/edit-profile",
  CHANGE_PASSWORD: "/change-password",
  CONNECTIONS: "/connects",
  ACCOUNT_LINKING: `/account/profile/connects`,
  CCPA_REQUEST: CCPA,
  DELETE_ACCOUNT: "/delete",
  CONTACT_SETTINGS: "/contact-settings",
  SUBSCRIPTION: `/subscription`,
  SUBSCRIPTION_PLAN: `/plan`,
  SUBSCRIPTION_PAYMENT_METHOD: `/payment-method`,
  SUBSCRIPTION_CHECKOUT: `/checkout`,
  SUBSCRIPTION_MANAGEMENT: `/management`,
  SUBSCRIPTION_BENEFIT_LOSS: `/benefit-loss`,
  SUBSCRIPTION_CANCELLATION_OFFER: `/cancelation-offer`,
  SUBSCRIPTION_PROMOTION_CHECKOUT: `/promotion-checkout`,
  SUBSCRIPTION_PROFILE: `${PROFILE}/subscription`,
  SUBSCRIPTION_PROFILE_PLAN: `${PROFILE}/subscription/plan`,
  SUBSCRIPTION_PROFILE_PLAN_NEW: `/account${PROFILE}/subscription/plan`,
  SUBSCRIPTION_PROFILE_CHECKOUT: `${PROFILE}/subscription/checkout`,
  SUBSCRIPTION_PROFILE_MANAGEMENT: `${PROFILE}/subscription/management`,
  SUBSCRIPTION_PROFILE_BENEFIT_LOSS: `${PROFILE}/subscription/benefit-loss`,
  SUBSCRIPTION_PROFILE_CANCELLATION_OFFER: `${PROFILE}/subscription/cancelation-offer`,
  SUBSCRIPTION_PROFILE_PROMOTION_CHECKOUT: `${PROFILE}/subscription/promotion-checkout`,
  LOGIN_AND_SECURITY: "/login-and-security",
  CHANGE_EMAIL: "/change-email",
  ADD_ADDITIONAL_EMAIL: "/add-additional-email",

  INFO: "/info",
  TERMS: "/info/terms-and-condition",
  PERSONAL: "/info/personal",

  TERMS_OF_USE: `${MAIN_URL}/terms-of-use`,
  PRIVACY_POLICY: `${MAIN_URL}/rentspree-privacy-policy`,
  DASHBOARD_V2: `${MAIN_URL}/dashboard/v2`,
  RENTER_DASHBOARD_URL: `${MAIN_URL}/dashboard/overview`,
  EXPLORE: "/explore",
  TENANT_SCREENING: "/tenant-screening",
  AGENT_DASHBOARD: "/dashboard/v2",
  RENTER_DASHBOARD: "/dashboard/overview",
  ROOT: "/",
  PROFILE,
  NOT_FOUND: "/not-found",
  TEMPORARY_ERROR: "/temporary-error",

  AUTH_TOKEN: "/auth/token",

  LINK_ACCOUNT: "/link-account",
  LINK_ACCOUNT_USER: "/link-account/:userType(applicant|owner)",

  MIGRATE: "/migrate",
  MIGRATE_PREPARE: "/migrate/prepare",

  OAUTH,
  OAUTH_USER,
  OAUTH_USER_EMAIL_EXIST: `${OAUTH_USER}/email`,
  OAUTH_USER_SOCIAL_EXIST: `${OAUTH_USER}/social/:social`,
  OAUTH_LINK_ACCOUNT: `${OAUTH}/link-account`,
  OAUTH_USER_LINK_ACCOUNT: `${OAUTH_USER}/link-account`,
  OAUTH_CREATE_PASSWORD: `${OAUTH_USER}/create-password`,
  VERSION: `${BASE_NAME}/version`,
  OAUTH_CLIENT_HANDLE_CODE: `/sso/:provider/authorize`,
  OAUTH_FINISH_PARTNER_SIGNUP: `${OAUTH}/finish/:partner`,

  CCPA,
  CCPA_DOWNLOAD_INFORMATION: `${CCPA}/download`,

  FINISH_PARTNER_SIGNUP: "/finish/:partner",
  EMAIL_VERIFICATION: "/verification/email",
  RENTER_EMAIL_VERIFICATION: "/renter-verification/email",
  EMAIL_VERIFICATION_VERIFY: "/verification/email/verify",

  CONTACT_US: `${MAIN_URL}/contact-us`,

  VERIFY_NEW_EMAIL: "/changing-email/:refId/verify",
  CHANGE_EMAIL_VERIFY_SUCCESS: "/changing-email/:refId/verify-success",
  CHANGE_EMAIL_REQUEST_EXPIRED: "/changing-email/expired",
  CHANGE_EMAIL_ERROR: "/changing-email/error",

  VERIFY_ADDITIONAL_EMAIL: "/adding-additional-email/:refId/verify",
  ADDITIONAL_EMAIL_VERIFY_SUCCESS:
    "/adding-additional-email/:refId/verify-success",
  ADD_ADDITIONAL_EMAIL_REQUEST_EXPIRED: "/adding-additional-email/expired",
  ADD_ADDITIONAL_EMAIL_ERROR: "/adding-additional-email/error",

  SET_UP_PASSWORD: "/setup-password",

  ONBOARDING,
  ONBOARDING_PROFILE: ONBOARDING_ROUTES[1],
  ONBOARDING_USER_TYPE: ONBOARDING_ROUTES[2],
  ONBOARDING_FIRST_TASK: ONBOARDING_ROUTES[3],
  ONBOARDING_FIRST_PROPERTY: ONBOARDING_ROUTES[4],
  ONBOARDING_USER_ROLE: ONBOARDING_ROUTES[5],

  ESIGN_DASHBOARD: "/esign-dashboard"
}

export const RedirectNotFound = () => (
  <Route
    render={() => {
      window.location.href = "/not-found"
    }}
  />
)

export const getFullPath = route => `${MAIN_URL}${BASE_NAME}${route}`

/**
 * Redux Constants
 */

export const GET_SSO_CONFIGS_CALL = "GET_SSO_CONFIGS_CALL"
export const GET_SSO_CONFIGS_REQUEST = "GET_SSO_CONFIGS_REQUEST"
export const GET_SSO_CONFIGS_SUCCESS = "GET_SSO_CONFIGS_SUCCESS"
export const GET_SSO_CONFIGS_FAILED = "GET_SSO_CONFIGS_FAILED"

export const getSSOConfigsCall = () => ({
  type: GET_SSO_CONFIGS_CALL
})
export const getSSOConfigsRequest = payload => ({
  type: GET_SSO_CONFIGS_REQUEST,
  payload
})
export const getSSOConfigsSuccess = payload => ({
  type: GET_SSO_CONFIGS_SUCCESS,
  payload
})
export const getSSOConfigsFailed = payload => ({
  type: GET_SSO_CONFIGS_FAILED,
  payload
})

export const CONTACT_SUPPORT_URL = "https://support.rentspree.com/en/contact-us"
