export const LOGIN_API_URL = "/api/v2/login"
export const FORGET_PASSWORD_API_URL = "/api/v2/requestResetPassword"
export const RESET_PASSWORD_API_URL = "/api/v2/resetPassword"
export const USERS_API_URL = "/api/v2/user"
export const LOGOUT_API_URL = `${USERS_API_URL}/logout`
export const PROFILE_API_URL = `${USERS_API_URL}/me`
export const CREATE_PASSWORD_API_URL = `${PROFILE_API_URL}/password`
export const LINK_ACCOUNT_API_URL = `${USERS_API_URL}/link-account`
export const UNLINK_ACCOUNT_API_URL = `${USERS_API_URL}/link-account/:partner`
export const FORCE_CHANGE_PASSWORD_API_URL = `${PROFILE_API_URL}/password/force`
export const PREPARE_MIGRATION_API_URL = `/api/v2/migrate/prepare`
export const MIGRATION_API_URL = `/api/v2/migrate`
export const REQUEST_PERSONAL_INFORMATION = `${USERS_API_URL}/ccpa/personalinformation/export`
export const GET_FILE_CCPA = `/ccpa/personalinformation/:personalInformationId`
export const GET_CCPA_REQUEST_LIST = `${USERS_API_URL}/ccpa/personalinformation`
export const DELETE_CCPA_FILE = `${USERS_API_URL}/ccpa/personalinformation/:personalInformationId`
export const GET_CONNECTED_PARTNER_URL = `${USERS_API_URL}/link-account`
export const IS_AGREEMENT_ACCEPTED = `/api/v2/agreements/:type/is-accepted`
export const GET_BROKERAGE_BY_PARTNER_AGENT = `/api/v2/user/me/brokerage`
export const ACCEPT_AGREEMENT = `/api/v2/agreements/:type/accept`
export const AGREEMENT_CONTENT = `/api/v2/agreements/:type`
export const EMAIL_VERIFICATION_VERIFY_URL = "/api/v2/email/verification/verify"
export const EMAIL_VERIFICATION_RESEND = "/api/v2/email/verification/resend"
export const EMAIL_VERIFICATION_RESEND_RENEW =
  "/api/v2/email/verification/resend/renew"
export const GET_SSO_CONFIGS_URL = "/api/v2/sso/config"
export const GET_SUBSCRIPTION_PRODUCT_API_URL = "/api/v2/products"
export const GET_USER_SUBSCRIPTION_API_URL = "/api/v2/subscriptions"
export const GET_STRIPE_MANAGE_SUBSCRIPTION_PORTAL_API_URL =
  "/api/v2/subscriptions/manage"
export const SELECT_TRIAL_PLAN_API_URL = "/api/v2/subscriptions/trial"
export const AGENT_PROFILE_API_URL = "/api/v3/agent/agent-profile"
export const CREATE_AGENT_PROFILE_PLACEHOLDER_API_URL = `${AGENT_PROFILE_API_URL}/placeholder`
export const GET_USER_SUBSCRIPTION_INVOICE_API_URL =
  "/api/v2/subscriptions/:subscriptionId/invoices"
export const DELETE_PAYMENT_METHOD_API_URL =
  "api/v2/subscriptions/:subscriptionId/payment-methods/:paymentId"
export const CHANGE_PAYMENT_METHOD_API_URL =
  "/api/v2/subscriptions/:subscriptionId/payment-methods"
export const CANCEL_SUBSCRIPTION_API_URL = "/api/v2/subscriptions/cancel"
export const PREVIEW_INVOICE_ENDPOINT =
  "/api/v2/subscriptions/:subscriptionId/invoice/preview"
export const PREVIEW_SUBSCRIPTION_ENDPOINT =
  "/api/v2/subscriptions/:subscriptionId/preview"
export const UPCOMING_INVOICE_ENDPOINT =
  "/api/v2/subscriptions/:subscriptionId/invoice/upcoming"
export const GET_USER_PROPERTIES = "/api/v2/agent/properties"

export const UPDATE_USER_ROLE_FOR_PROPERTY_API_URL =
  "/api/v2/properties/:propertyId/user-role-for-property"

// Auth0 Authentication API
export const REVOKE_REFRESH_TOKEN = "/oauth/revoke"
