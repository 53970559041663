import React, { useState, useEffect } from "react"
import { feedbackCollectionInstance } from "utils/feedback-collection/instance"

import { useAuth } from "hooks/auth/use-auth"
import { injectRequestInterceptor } from "utils/axios-utils"
import { AuthLoadingPlaceholder } from "containers/router/auth-router/callback"
import AuthContext from "./auth.context"

export const AuthWrapper = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessToken } = useAuth()
  const [isInitializedAuth, setIsInitializedAuth] = useState(false)

  useEffect(
    () => {
      if (isLoading) return
      injectRequestInterceptor({
        getAccessToken: isAuthenticated ? getAccessToken : undefined
      })
      if (isAuthenticated) {
        feedbackCollectionInstance.setAuthConfiguration({
          getAccessToken
        })
      }
      setIsInitializedAuth(true)
    },
    [isAuthenticated, isLoading, getAccessToken]
  )

  if (!isInitializedAuth) return <AuthLoadingPlaceholder />

  return (
    <AuthContext.Provider value={{ authContext: true }}>
      {children}
    </AuthContext.Provider>
  )
}
