import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import injectReducer from "utils/inject-reducer"

import { makeSelectToasts } from "./selectors"
import { closeToast } from "./actions"
import reducer from "./reducer"
import { KEY } from "./constants"

const mapStateToProps = createStructuredSelector({
  toasts: makeSelectToasts()
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeToast
      },
      dispatch
    )
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export const withReducer = injectReducer({
  key: KEY,
  reducer
})
