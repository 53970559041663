import cloneDeep from "lodash/cloneDeep"

export function addCategory(events, categoryName) {
  const newEvent = cloneDeep(events)
  Object.keys(events).forEach(key => {
    newEvent[key] = {
      ...events[key],
      properties: {
        event_category: categoryName
      }
    }
  })
  return newEvent
}

export const generateAmplitudeProfileItem = (value, setOnce = false) => ({
  value,
  setOnce
})

export const addCustomProperties = (event, customProperties = {}) => ({
  ...event,
  properties: {
    ...event.properties,
    ...customProperties
  }
})

export const categories = {
  pageEntry: "[Auth 2.0] Page entry",
  signup: "[Auth 2.0] Sign up",
  userType: "[Auth 2.0] User type",
  signupInfo: "[Auth 2.0] Sign up info",
  login: "[Auth 2.0] Log in",
  ssoLogin: "[Auth 2.0] SSO Log in",
  linkAccountWithPartner: "[Auth 2.0] Link account with partner",
  ccpaRequest: "[Auth 2.0] CCPA Request",
  unlinkPartner: "[Auth 2.0] Unlink partner account",
  subscription: "[Auth 2.0] Subscription",
  rentspreeProSubscription: "RentSpree Pro (Subscription)",
  changeEmail: "change email",
  addAdditionalEmail: "add additional email",
  setUpPassword: "set up password"
}

export const pageEntryActions = {
  enterAuthWeb: { action: "Enters auth web" },
  enterFromRentspreeWeb: { action: "Enters from Rentspree web" },
  enterFromAppWeb: { action: "Enters from Application web" },
  enterFromRenterDashboard: { action: "Enters from Renter dashboard" },
  enterFromAgentDashboard: { action: "Enters from Agent dashboard" },
  enterSignupPage: { action: "Enters sign up page" }
}

export const signupActions = {
  signupPassword: { action: "Sign up with password" },
  continueFacebook: { action: "Continue with Facebook" },
  continueGoogle: { action: "Continue with Google" },
  enterRequirePassword: { action: "Enter require password" },
  proceedThroughSignup: { action: "proceed through sign up" }
}

export const userTypeActions = {
  selectUserType: { action: "Select User Type" }
}

export const signupInfoActions = {
  acceptTerms: { action: "Accept TU terms" }
}

export const loginActions = {
  loginPassword: { action: "Login with password" },
  resetPasswordRequest: { action: "Reset password request" },
  resetPasswordResend: { action: "Reset password resend link" },
  createPassword: { action: "Create new password from reset password" },
  forceChangePassword: { action: "Change expired password" },
  entersLoginPage: { action: "Enters login page" }
}

export const ssoLoginActions = {
  completeAuthentication: { action: "Complete Authentication" }
}

export const listingPartner = {
  enterAuthPage: { action: "Enters Authorization page" },
  authWithPartnerAccount: { action: "Authorize with partner account" },
  authWithPartnerAccountFail: { action: "Authorize with partner account fail" },
  completeMailingAddress: { action: "Complete mailing address auth" },
  autoInitiateWithPartner: { action: "Auto initiate with partner" },
  clickContinueWithPartnerButton: {
    action: "Click continue with partner button"
  }
}

export const linkAccountWithPartnerActions = {
  clickLinkAccountWithPartner: { action: "Click link account with partner" },
  linkAccountWithPartner: { action: "Link account with partner" },
  linkAccountWithPartnerFailed: { action: "Link account with partner fail" },
  validateNRDSId: { action: "Validate NRDS ID" },
  seeNRDSIdPopup: { action: "See NRDS ID popup" },
  lookupNRDSId: { action: "Lookup NRDS ID" }
}
export const ccpaRequstActions = {
  downloadInformation: { action: "Download CCPA information" },
  deleteInformation: { action: "Delete CCPA information" }
}

export const unlinkPartnerActions = {
  clickOnUnlink: { action: "Click on unlink" },
  clickConfirmModal: { action: "Click confirm modal" },
  closeConfirmModal: { action: "Close confirm modal" },
  seeUnlinkUnsuccessTab: {
    action: "See unlink unsuccess tab"
  },
  seeUnlinkUnsuccessModal: {
    action: "See unlink unsuccess modal"
  },
  seeUnlinkSuccessTab: {
    action: "See unlink success tab"
  },
  clickOnHowToLink: {
    action: "Click on How to Link"
  }
}

export const subscriptionAction = {
  addCoupon: { action: "Click add coupon" },
  applyCoupon: { action: "Apply coupon" }
}

export const rentspreeProAction = {
  clickMonthlyYearlyToggle: {
    action: "click monthly yearly toggle",
    propertyOptions: {
      page: {
        choosePlan: "choose_plan",
        checkout: "checkout"
      }
    }
  },
  clickChoosePlan: {
    action: "click choose plan",
    propertyOptions: {
      clickFrom: {
        myAccount: "my_account",
        managementRenew: "management_renew",
        checkout: "checkout"
      }
    }
  },
  clickSubscriptionManagement: {
    action: "click subscription management",
    propertyOptions: {
      clickFrom: {
        myAccount: "my_account"
      }
    }
  },
  clickBuySubscription: {
    action: "click buy subscription",
    propertyOptions: {
      savedPayment: "saved_payment",
      newPayment: "new_payment"
    },
    productName: "rentspree_pro"
  },
  clickEditPayment: {
    action: "click edit payment"
  },
  clickViewInvoice: {
    action: "click view invoice"
  },
  clickCancelSubscription: {
    action: "click cancel subscription"
  },
  clickConfirmOnCancelSubscription: {
    action: "click confirm on cancel subscription",
    propertyOptions: {
      clickFrom: {
        proBenefitLossPage: "pro_benefit_loss_page",
        cancellationOfferPage: "cancellation_offer_page"
      }
    }
  },
  clickGoBackOnCancelSubscription: {
    action: "click go back on cancel subscription",
    propertyOptions: {
      clickFrom: {
        proBenefitLossPage: "pro_benefit_loss_page",
        cancellationOfferPage: "cancellation_offer_page",
        cancellationCheckoutPage: "cancellation_checkout_page"
      }
    }
  },
  clickContinueToCancel: {
    action: "click continue to cancel",
    propertyOptions: {
      clickFrom: {
        proBenefitLossPage: "pro_benefit_loss_page"
      }
    }
  },
  clickCheckout: {
    action: "click checkout",
    propertyOptions: {
      actionNeeded: {
        trial: "trial",
        upgrade: "upgrade"
      },
      clickFrom: {
        choosePlan: "choose_plan",
        managementReactivate: "management_reactivate",
        managementUpdatePayment: "management_update_payment"
      },
      subscriptionPeriod: {
        monthly: "monthly",
        yearly: "yearly"
      }
    }
  },
  clickDeletePayment: {
    action: "click delete payment"
  },
  clickConfirmOnDeletePayment: {
    action: "click confirm on delete payment"
  },
  clickGoBackOnDeletePayment: {
    action: "click go back on delete payment"
  },
  clickSaveEditPayment: {
    action: "click save edit payment"
  },
  enterProBenefitLossPage: {
    action: "enter pro benefit loss page"
  },
  enterCancellationOfferPage: {
    action: "enter cancellation offer page"
  },
  clickClaimOffer: {
    action: "click claim offer",
    propertyOptions: {
      clickFrom: {
        cancellationOfferPage: "cancellation_offer_page"
      }
    }
  },
  enterCancellationCheckoutPage: {
    action: "enter cancellation checkout page"
  },
  clickConfirmCheckoutWithOffer: {
    action: "click confirm checkout with offer",
    propertyOptions: {
      clickFrom: {
        cancellationCheckoutPage: "cancellation_checkout_page"
      }
    }
  }
}

const unlinkPartnerNoCategory = {
  unlinkAccountWithPartner: {
    action: "Unlink account with partner"
  }
}

export const contactSettingsEvent = {
  clickTurnOffAutoAddContacts: {
    action: "Contact - Click turn off auto-add contacts"
  },
  cancelTurnOffAutoAddContact: {
    action: "Contact - Cancel turn off auto-add contacts"
  },
  confirmTurnOffAutoAddContact: {
    action: "Contact - Confirm turn off auto-add contacts"
  },
  clickTurnOnAutoAddContacts: {
    action: "Contact - Click turn on auto-add contacts"
  }
}

export const pageEntryEvents = addCategory(
  pageEntryActions,
  categories.pageEntry
)
export const signupEvents = addCategory(signupActions, categories.signup)
export const userTypeEvents = addCategory(userTypeActions, categories.userType)
export const signupInfoEvents = addCategory(
  signupInfoActions,
  categories.signupInfo
)
export const loginEvents = addCategory(loginActions, categories.login)

export const ssoLoginEvents = addCategory(ssoLoginActions, categories.ssoLogin)

export const linkAccountWithPartnerEvents = addCategory(
  linkAccountWithPartnerActions,
  categories.linkAccountWithPartner
)

export const ccpaRequest = addCategory(
  ccpaRequstActions,
  categories.ccpaRequest
)

export const unlinkPartnerEvents = addCategory(
  unlinkPartnerActions,
  categories.unlinkPartner
)

export const subscriptionEvent = addCategory(
  subscriptionAction,
  categories.subscription
)

export const EMAIL_VERIFICATION_ERROR_TYPE = {
  VERIFICATION_ERROR: "verification error",
  REQUEST_EXPIRED: "request expired"
}

export const changeAndAdditionalEmailEvents = {
  enterLoginAndSecurityPage: {
    action: "enter login & security page"
  },
  changeEmail: {
    viewChangeEmailPage: { action: "view change email page" },
    clickChangeEmail: { action: "click change email" },
    resendVerificationEmail: {
      action: "resend verification email",
      type: categories.changeEmail
    },
    viewChangeEmailSuccessPage: { action: "view change email success page" },
    viewChangeEmailFailurePage: {
      action: "view change email fail page",
      requestExpiredType: EMAIL_VERIFICATION_ERROR_TYPE.REQUEST_EXPIRED,
      verificationErrorType: EMAIL_VERIFICATION_ERROR_TYPE.VERIFICATION_ERROR
    }
  },
  additionalEmail: {
    viewAddAdditionalEmailPage: { action: "view add additional email page" },
    clickAddAdditionalEmail: { action: "click add additional email" },
    resendVerificationEmail: {
      action: "resend verification email",
      type: categories.addAdditionalEmail
    },
    clickMakePrimary: { action: "click make primary" },
    confirmMakePrimary: { action: "confirm make primary" },
    cancelMakePrimary: { action: "cancel make primary" },
    clickDeleteAdditionalEmail: { action: "click delete additional email" },
    cancelDeleteAdditionalEmail: { action: "cancel delete additional email" },
    confirmDeleteAdditionalEmail: { action: "confirm delete additional email" },
    viewAddAdditionalEmailSuccessPage: {
      action: "view add additional email success page"
    },
    viewAddAdditionalEmailFailPage: {
      action: "view add additional email fail page",
      requestExpiredType: EMAIL_VERIFICATION_ERROR_TYPE.REQUEST_EXPIRED,
      verificationErrorType: EMAIL_VERIFICATION_ERROR_TYPE.VERIFICATION_ERROR
    }
  },
  setupPasswordPage: {
    clickProceedToSetupPassword: {
      action: "click proceed to set up password",
      sourceAddAdditionalEmail: categories.addAdditionalEmail,
      sourceSetUpPassword: categories.setUpPassword
    },
    cancelSetupPassword: {
      action: "cancel set up password",
      sourceAddAdditionalEmail: categories.addAdditionalEmail,
      sourceSetUpPassword: categories.setUpPassword
    },
    viewSetupPasswordPage: {
      action: "view set up password page",
      sourceAddAdditionalEmail: categories.addAdditionalEmail,
      sourceSetUpPassword: categories.setUpPassword
    },
    clickSetupPassword: {
      action: "click set up password",
      sourceAddAdditionalEmail: categories.addAdditionalEmail,
      sourceSetUpPassword: categories.setUpPassword
    }
  }
}

export const trackerEvents = {
  ...pageEntryEvents,
  ...signupEvents,
  ...userTypeEvents,
  ...signupInfoEvents,
  ...loginEvents,
  ...listingPartner,
  ...ssoLoginEvents,
  ...linkAccountWithPartnerEvents,
  ...ccpaRequest,
  ...unlinkPartnerEvents,
  ...unlinkPartnerNoCategory,
  ...contactSettingsEvent,
  ...subscriptionEvent
}
