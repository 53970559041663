import get from "lodash/get"
import { getLocalItem } from "@rentspree/cookie"
import { STORAGE } from "constants/cookie"

export function timestampConfig() {
  return { params: { t: new Date().getTime() } }
}

export const axiosConfig = (
  accessToken = getLocalItem(STORAGE.USER_TOKEN).access_token,
  otherConfig
) => {
  const headers = get(otherConfig, "headers", {})
  headers.Authorization = `Bearer ${accessToken}`
  return { ...otherConfig, headers }
}
