import styled, { css } from "styled-components"
import {
  BUTTON_HEIGHT,
  LARGE_BUTTON_BORDER_RADIUS,
  SMALL_BUTTON_PADDING,
  SMALL_BUTTON_BORDER_RADIUS,
  FLEX_CENTER,
  COLOR
} from "styles/settings"
import * as color from "./buttons-color"
import { Span } from "../typography"
import { B12 } from "../typography/text"

export const largeBtn = css`
  width: 100%;
  border-radius: ${LARGE_BUTTON_BORDER_RADIUS};
`

export const smallBtn = css`
  padding: 0px ${SMALL_BUTTON_PADDING};
  border-radius: ${SMALL_BUTTON_BORDER_RADIUS};
`

const grayDisabled = css`
  background: ${COLOR.veryLightGray};
  cursor: not-allowed;
`

const isDisabled = css`
  background: ${COLOR.grayScale15};
  color: ${COLOR.mediumGray};
  cursor: not-allowed;
  border: unset;
`

export const StyledButton = styled(Span).attrs(() => ({ as: "button" }))`
  ${FLEX_CENTER};
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: ${BUTTON_HEIGHT};
  ${props => props.socialLoginBtn && color.socialLoginBtn};
  ${props => props.google && color.googleBtn};
  ${props => props.facebook && color.facebookBtn};
  ${props => props.transparent && color.transparentBtn};
  ${props => props.blueGradientHovered && color.blueGradientHovered};
  ${props => props.greenGradient && color.greenGradientBtn};
  ${props => props.emailBtn && color.emailBtn};
  ${props => props.blackText && color.blackTextBtn};
  ${props => props.large && largeBtn};
  ${props => props.small && smallBtn};
  ${props => props.blueText && color.blueText};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.minWidth && `min-width: ${props.minWidth}px`};
  &:hover > .background {
    transition: all, 0.5s;
    transform: translateX(50%);
  }
  &:hover {
    ${props => props.hoverOpacity && `opacity: ${props.hoverOpacity}`};
  }
  ${props => props.grayDisabled && grayDisabled};
  ${props => props.disabled && isDisabled};
`
export const BlueGradientBg = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
  z-index: -1;
  ${color.blueGradient};
  transition: all, 0.5s;
  &:hover {
    transition: all, 0.5s;
    transform: translateX(50%);
  }
  ${props => props.grayDisabled && grayDisabled};
`
export const ButtonIcon = styled.i`
  font-size: 18px;
  line-height: 40px;
  ${props => props.ml && "margin-left: 5px"};
  ${props => props.mr && "margin-right: 5px"};
`
export const iconStyle = css`
  position: absolute;
  z-index: 1;
  left: 0px;
  width: 48px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 48px;
  font-size: 10px;
  ${FLEX_CENTER};
  > img {
    width: 18px;
  }
`
export const SocialButtonIcon = styled.div`
  ${iconStyle};
  font-size: 14px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 30px;
`

export const defaultButtonWhiteTheme = {
  default: {
    fg: COLOR.white,
    bg: "transparent",
    hfg: "#405369",
    hbg: COLOR.veryLightGray
  },
  swap: {
    fg: "#405369",
    bg: COLOR.white
  }
}

export const WrapperButtonWhite = styled.div`
  & > button {
    margin-bottom: 10px;
    width: 220px;
    font-weight: 600;
    color: ${props => props.theme.fg};
    border: 1px solid ${props => props.theme.fg};
    background: ${props => props.theme.bg};
    &:hover {
      background-color: ${props => props.theme.hbg};
      color: ${props => props.theme.hfg};
      border: 1px solid ${props => props.theme.hfg};
    }
    transition: all, 0.5s;
  }
`

export const SwitchToggleButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 188px;
  height: 40px;
  padding: 1px;
  background: ${COLOR.white};
  border: 1px solid ${COLOR.lineGray};
  border-radius: 23px;
  z-index: 1;
`
export const ToggleInput = styled.input`
  display: none;
  & + label {
    cursor: pointer;
    &:after {
      background: ${COLOR.deepBlue};
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
      z-index: -1;
      border-radius: 23px;
    }
  }

  &.first-toggle + label {
    &:after {
      margin-left: 4px;
      left: 100%;
    }
  }
  &.second-toggle + label {
    &:after {
      margin-left: -4px;
      left: -100%;
    }
  }
  &:checked + label {
    cursor: default;
    color: ${COLOR.white};
    transition: color 200ms;
    &:after {
      margin-left: 0;
      left: 0;
    }
  }
`
export const ToggleButton = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR.textBlack};
  width: 90px;
  height: 100%;
  transition: color 600ms ease;
`

export const BillingPeriodButtonSubtitle = styled(B12)`
  position: inherit;
  top: -3px;
`
