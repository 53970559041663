import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import merge from "lodash/merge"
import cloneDeep from "lodash/cloneDeep"
import axios from "axios"
import getHandleAuthorization from "@rentspree/axios-refresh-token"
import {
  API_URL,
  USER_API_URL,
  DOMAIN_NAME,
  FILE_API_URL,
  AUTH_PROVIDER_DOMAIN
} from "env"
import { getLocalItem } from "@rentspree/cookie"
import { STORAGE } from "constants/cookie"
import { timestampConfig } from "./axios-config"

axios.interceptors.response.use(
  response => response.data,
  error => {
    throw error.response
  }
)

// ====== instance for after login api =====
let afterLoginRequestInterceptorId = null
const afterLoginInstance = axios.create({
  baseURL: USER_API_URL
})

const handleAuthorization = getHandleAuthorization({
  domain: DOMAIN_NAME,
  authAPIBase: USER_API_URL,
  axiosInstance: afterLoginInstance
})

afterLoginInstance.interceptors.response.use(
  response => response.data,
  handleAuthorization
)

// ====== instance for user api =====
const userApiInstance = axios.create({
  baseURL: USER_API_URL
})

// TODO: Check if user api instance can implement interceptor to automatically attach access token
userApiInstance.interceptors.request.use(config => {
  merge(config, timestampConfig())
  return config
})

userApiInstance.interceptors.response.use(
  response => response.data,
  error => {
    throw error.response
  }
)

// ====== instance for before login api =====
const beforeLoginInstance = axios.create({
  baseURL: API_URL
})

beforeLoginInstance.interceptors.response.use(
  response => response,
  error => {
    throw error
  }
)

beforeLoginInstance.interceptors.request.use(config => {
  merge(config, timestampConfig())
  return config
})

// ====== instance for file api =====
let fileApiRequestInterceptorId = null
const fileApiInstance = axios.create({
  baseURL: FILE_API_URL
})

const handleAuthorizationOfFileApi = getHandleAuthorization({
  domain: DOMAIN_NAME,
  authAPIBase: FILE_API_URL,
  axiosInstance: fileApiInstance
})

fileApiInstance.interceptors.response.use(
  response => response,
  handleAuthorizationOfFileApi
)

// ====== instance for gateway api =====
let mainApiRequestInterceptorId = null
const mainApiInstance = axios.create({
  baseURL: API_URL
})

const handleAuthorizationOfMainApi = getHandleAuthorization({
  domain: DOMAIN_NAME,
  authAPIBase: API_URL,
  axiosInstance: mainApiInstance
})

mainApiInstance.interceptors.response.use(
  response => response.data,
  handleAuthorizationOfMainApi
)

// ====== inject request interceptor =====
const injectRequestInterceptor = ({ getAccessToken }) => {
  const requestInterceptor = async config => {
    const defaultConfig = {
      headers: {
        post: {
          "Content-Type": "application/json"
        }
      }
    }
    const configMerge = merge(cloneDeep(defaultConfig), config)
    const headers = get(configMerge, "headers", {})
    // Auth0 Migration
    let accessToken
    if (getAccessToken) {
      accessToken = await getAccessToken()
    } else {
      accessToken = get(getLocalItem(STORAGE.USER_TOKEN), "access_token")
    }
    if (!isEmpty(accessToken)) {
      headers.Authorization = `Bearer ${accessToken}`
    }

    return { ...configMerge, headers, ...timestampConfig() }
  }

  if (fileApiRequestInterceptorId !== null) {
    fileApiInstance.interceptors.request.eject(fileApiRequestInterceptorId)
  }
  fileApiRequestInterceptorId = fileApiInstance.interceptors.request.use(
    requestInterceptor
  )

  if (afterLoginRequestInterceptorId !== null) {
    afterLoginInstance.interceptors.request.eject(
      afterLoginRequestInterceptorId
    )
  }
  afterLoginRequestInterceptorId = afterLoginInstance.interceptors.request.use(
    requestInterceptor
  )

  if (mainApiRequestInterceptorId !== null) {
    mainApiInstance.interceptors.request.eject(mainApiRequestInterceptorId)
  }
  mainApiRequestInterceptorId = mainApiInstance.interceptors.request.use(
    requestInterceptor
  )
}

// ====== instance for Auth0 authentication api =====
const authApiInstance = axios.create({
  baseURL: `https://${AUTH_PROVIDER_DOMAIN}`
})
// ====================================

export {
  userApiInstance,
  afterLoginInstance,
  fileApiInstance,
  mainApiInstance,
  beforeLoginInstance,
  authApiInstance,
  injectRequestInterceptor
}
