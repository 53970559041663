import React from "react"
import { css } from "styled-components"
import GlobalStyle from "@rentspree/component-v2/dist/style/global"
import { SWEET_ALERT_STYLE } from "./sweetalert/sweetalert"

export const customGlobal = css`
  html,
  body {
    height: auto;
    width: 100%;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    @media (max-width: 991px) {
      height: 100%;
    }
  }

  body {
    font-family: "Source Sans Pro", "Helvetica Neue", "simple-line-icons",
      Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  #app {
    height: 100%;
    width: 100%;
  }

  p,
  label,
  button {
    font-family: "Source Sans Pro", Georgia, Times, "Times New Roman", serif;
  }

  a {
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: not-allowed;
  }
  ${SWEET_ALERT_STYLE};
`

export const GlobalStyles = () => <GlobalStyle customGlobal={customGlobal} />
