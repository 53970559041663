import { COLOR } from "styles/settings"
import styled from "styled-components"
import S24 from "@rentspree/component-v2/dist/typography/s24"
import T36 from "@rentspree/component-v2/dist/typography/t36"
import { Span } from "./text-styled"

export const H1 = styled(Span).attrs(() => ({ as: S24 }))`
  color: ${props => (props.color ? props.color : COLOR.darkGray)};
`

export const H2 = styled(Span).attrs(() => ({ as: "h2" }))`
  font-size: ${props => (props.size ? props.size : "20px")};
`

export const H3 = styled(Span).attrs(() => ({ as: "h3" }))`
  color: ${props => (props.color ? props.color : COLOR.brightBlue)};
  font-size: ${props => (props.size ? props.size : "18px")};
`

export const H4 = styled(Span).attrs(() => ({ as: "h4" }))`
  font-size: ${props => (props.size ? props.size : "16px")};
`

export const Title = styled(Span).attrs(() => ({ as: T36 }))`
  font-size: ${props => (props.size ? props.size : "36px")};
  line-height: 45px;
  @media (max-width: 991px) {
    font-size: ${props => (props.mSize ? props.mSize : "24px")};
    line-height: 32px;
  }
`
