import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"

import { makeSelectError } from "containers/error/selectors"
import { makeSelectIsOAuthRedirect } from "containers/wrapper/selectors"
import { makeSelectSSOConfigsList } from "containers/router/selectors"
import { openSweetAlert } from "../../libs/sweet-alert"
import { closeError } from "./actions"

const mapStateToProps = createStructuredSelector({
  error: makeSelectError(),
  isOAuthRedirect: makeSelectIsOAuthRedirect(),
  ssoDetails: makeSelectSSOConfigsList()
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ closeError, openSweetAlert }, dispatch)
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
