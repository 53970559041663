import React from "react"
import { compose } from "redux"
import ToastFloater from "components/molecules/toast-floater"
import { withConnect, withReducer } from "./connect"

export const Toast = ({ toasts, position, closeToast, timeOut, width }) => (
  <ToastFloater
    toasts={toasts}
    position={position}
    onClose={closeToast}
    timeOut={timeOut}
    width={width}
  />
)

export default compose(
  withConnect,
  withReducer
)(Toast)
