import { css } from "styled-components"
import { COLOR } from "../settings"
export const SWEET_ALERT_STYLE = css`
  .rentspree-sweetalert {
    .swal-footer {
      text-align: center;
      button.swal-button.swal-button--confirm {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 9px 25px;
        gap: 10px;

        width: 86px;
        height: 40px;
        background: linear-gradient(
          90deg,
          ${COLOR.primaryDarkBlue} -0.73%,
          ${COLOR.newDeepBlue} 100.02%
        );
        border-radius: 20px;

        flex: none;
        order: 0;
        flex-grow: 0;

        &:hover {
          background: ${COLOR.deepBlueDark};
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
    .swal-content {
      > div {
        word-wrap: break-word;
      }
    }
    .swal-text {
      padding: 0 20px;
      text-align: center;
    }
  }

  .rentspree-sweetalert-error {
    padding: 20px;
    margin: 0;
    @media (min-width: 500px) {
      width: 350px;
    }
    .swal-footer {
      padding: 0;
      margin: 0;
      text-align: center;
      .swal-button.swal-button--confirm {
        color: ${COLOR.textBlack};
        height: 40px;
        padding: 0px 28px;
        font-size: 16px;
        line-height: 22px;
        background: ${COLOR.white};
        border: 1px solid ${COLOR.lightGray};
        border-radius: 29px;
        transition: all ease-in-out 0.3s;
        &:hover {
          background-color: rgb(241, 241, 241);
        }
        &:focus {
          box-shadow: none;
        }
      }
      .swal-button-container {
        margin: 0;
      }
    }
    .swal-icon.swal-icon--error {
      margin: 0px auto 20px auto;
    }
    .swal-title {
      color: ${COLOR.textBlack};
      font-size: 24px;
      display: block;
      line-height: 32px;
      padding: 0px;
    }
    .swal-title:not(:last-child) {
      margin-bottom: 5px;
    }
    .swal-content {
      > div {
        word-wrap: break-word;
      }
    }
    .swal-text {
      color: ${COLOR.textBlack};
      max-width: none;
      padding: 0;
      margin-bottom: 20px;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .custombutton {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 9px 25px !important;
    gap: 10px !important;

    width: auto !important;
    min-width: 86 !important;
    height: 40px !important;
    border-radius: 20px !important;

    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    flex: none !important;
    order: 0 !important;
    flex-grow: 0 !important;

    text-align: center;
  }
  .custombutton-success {
    background: linear-gradient(
      90deg,
      ${COLOR.primaryDarkBlue} -0.73%,
      ${COLOR.newDeepBlue} 100.02%
    ) !important;
    &:hover {
      background: ${COLOR.deepBlueDark} !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .custombutton-error {
    color: ${COLOR.textBlack} !important;
    background: ${COLOR.white} !important;
    /* Placeholder Grey */

    border: 1px solid ${COLOR.lightGray} !important;
  }
  .custom-htmlcontainer {
    margin: 0.3em !important;
  }
  .custom-title {
    padding: 0.2em !important;
  }
  .custom-icon {
    margin: 0.5em auto !important;
    .swal2-success-ring {
      border: 0.25em solid ${COLOR.green} !important;
    }
    .swal2-success-line-tip {
      background-color: ${COLOR.green} !important;
    }
    .swal2-success-line-long {
      background-color: ${COLOR.green} !important;
    }
  }
  .custom-actions {
    margin: 5px !important;
  }
  .custom-closebutton {
    justify-content: right !important;
    margin-top: -0.25em !important;
  }
  .custom-popup {
    max-width: 475px !important;
  }
`
