export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount"
export const DAEMON = "@@saga-injector/daemon"
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount"
export const CONVERT_CASE = {
  USD: "$",
  COMMA: ",",
  PHONE_NUMBER: "phoneNumber",
  LOCAL_DATE: "dateOfLocal"
}
export const MOBILE_MAX_WIDTH = 991

export const SNACKBAR_SHOW_DURATION = {
  SHORT_TEXT: 3000,
  LONG_TEXT: 6000,
  ACTION_TEXT: 10000
}
