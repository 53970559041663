import React from "react"
import styled from "styled-components"

import ErrorMessage from "containers/error"

export const StyledWrapper = styled.div`
  height: 100%;
  ${props => props.bgColor && `background: ${props.bgColor}`};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth};
    margin: auto;
    `};
`

export const Div = styled.div`
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.padding && `passing: ${props.padding}`};
  ${props => props.center && "text-align: center"};
  @media (max-width: 767px) {
    ${props => props.mCenter && "text-align: center"};
  }
`

export const OAuthWrapper = ({ children, error, location }) => (
  <>
    {error && <ErrorMessage location={location} />}
    <StyledWrapper bgColor="white" padding="30px" maxWidth="408px">
      {children}
    </StyledWrapper>
  </>
)
