export const LOGIN_CALL = "LOGIN_CALL"
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const CLEAR_TOKEN = "CLEAR_TOKEN"
export const AUTH0_LOGIN_CALL = "AUTH0_LOGIN_CALL"

export const loginCall = payload => ({ type: LOGIN_CALL, payload })
export const loginRequest = payload => ({ type: LOGIN_REQUEST, payload })
export const loginSuccess = payload => ({ type: LOGIN_SUCCESS, payload })
export const loginFailed = payload => ({ type: LOGIN_FAILED, payload })
export const auth0LoginCall = payload => ({ type: AUTH0_LOGIN_CALL, payload })
