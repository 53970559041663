import React, { useState } from "react"
import * as crypto from "crypto"
import { getLocalItem, setLocalItem } from "@rentspree/cookie"
import merge from "lodash/merge"

import { StatsigProvider } from "statsig-react"

import { STATSIG_API, STATSIG_ENVIRONMENT, STATSIG_SDK_KEY } from "../../env"
import { LoadingSpin } from "../../containers/page-subscription-checkout/styled-index"
import { STORAGE } from "../../constants/cookie"
import { parse } from "../../utils/query-parser"
import history from "../../utils/history"
import { getSubdomain } from "../../utils/subdomain"

const STORAGE_KEY = "STATSIG_LOCAL_STORAGE_STABLE_ID"

/*
  This function based on this link
  https://docs.statsig.com/client/javascript-sdk/stable-id/#persisting-stableid-across-subdomain
 */
const getStableId = () => {
  const generateUUID = () => {
    if (crypto && crypto.randomUUID) return crypto.randomUUID()

    const randomSegment = () =>
      Math.floor(65536 * Math.random())
        .toString(16)
        .padStart(4, "0")

    return `${randomSegment()}${randomSegment()}-${randomSegment()}-4${randomSegment().substring(
      1
    )}-${randomSegment()}-${randomSegment()}${randomSegment()}${randomSegment()}`
  }

  const localStorageUUID = localStorage.getItem(STORAGE_KEY) || null
  const cookieUUID = getLocalItem(STORAGE.USER_STABLE_ID)

  if (!cookieUUID) {
    const newUUID = generateUUID()
    localStorage.setItem(STORAGE_KEY, newUUID)
    setLocalItem(STORAGE.USER_STABLE_ID, newUUID)
    return newUUID
  }

  if (!localStorageUUID || cookieUUID !== localStorageUUID) {
    localStorage.setItem(STORAGE_KEY, cookieUUID)
  }

  return cookieUUID
}

/*
  auth-web cannot detect user subdomain directly,
  we need to detect it from continue query string with contains the real URL with subdomain.
 */
const getCurrentUserSubdomain = () => {
  const url = parse(history.location.search).continue || null
  return getSubdomain(url)
}

export const FeatureFlagProvider = ({ children }) => {
  const [user, setUser] = useState({
    userID: "default-id",
    custom: {
      subdomain: getCurrentUserSubdomain()
    },
    customIDs: {
      stableID: getStableId()
    }
  })

  const setUserState = updateUser => {
    setUser(prevUserState => merge({}, prevUserState, updateUser))
  }

  return (
    <StatsigProvider
      sdkKey={STATSIG_SDK_KEY}
      waitForInitialization
      initializingComponent={<LoadingSpin />}
      user={user}
      setUser={setUserState}
      options={{
        environment: {
          tier: STATSIG_ENVIRONMENT
        },
        api: STATSIG_API
      }}>
      {children}
    </StatsigProvider>
  )
}
