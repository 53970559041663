import swal from "sweetalert"

export const ALERT_PRESET = {
  SUCCESS: {
    icon: "success",
    confirmButtonText: "OK",
    className: "rentspree-sweetalert"
  },
  ERROR: {
    icon: "error",
    confirmButtonText: "Close",
    className: "rentspree-sweetalert-error"
  },
  INFO: {
    icon: "info",
    confirmButtonText: "OK",
    className: "rentspree-sweetalert"
  },
  WARNING: {
    icon: "warning",
    confirmButtonText: "OK",
    className: "rentspree-sweetalert"
  }
}

export const ALERT_MESSAGE = {
  GET_PROFILE_ERROR: {
    title: "Please try again",
    text:
      "Sorry, we are having trouble accessing your profile. Please refresh the page and try again.",
    button: "Retry"
  },
  GET_CONNECTED_PARTNER: {
    title: "Please try again",
    text:
      "Sorry, we are having trouble accessing your account linking. Please refresh the page and try again.",
    button: "Retry"
  },
  UNAUTHORIZED_ERROR: {
    title: "You don't have permission to access this page",
    text:
      "Please refresh the page and try again. If you still have trouble, contact us at (323) 515-7757 or support@rentspree.com.",
    button: "Retry"
  },
  GENERAL_ERROR: {
    title: "Something went wrong",
    text:
      "Please try again. If the problem persists, please contact support@rentspree.com or (323) 515-7757.",
    button: "Try Again"
  },
  CCPA_DOWNLOAD_ERROR: {
    title: "Error downloading your information",
    text:
      "Sorry, we're having trouble downloading your information. Please close this menu and try to download again. If the problem persists, contact support@rentspree.com or (323) 515-7757.",
    button: "Close"
  },
  CCPA_DELETE_ERROR: {
    title: "Unable to delete the file",
    text:
      "Please try again in a few minutes. If the problem persist, contact support@rentspree.com or (323) 515-7757.",
    button: "Close"
  },
  CCPA_DELETE_SUCCESS: {
    title: "Successfully delete the file",
    text: "The file is deleted.",
    button: "Close"
  },
  DELETE_GENERAL_ERROR: {
    title: "Error deleting your account ",
    text:
      "Sorry, we're having trouble deleting your account. Please refresh and try again. If the problem persists, contact support@rentspree.com or (323) 515-7757.",
    button: "Try Again"
  },
  QUESTION_SESSION_TIMEOUT: {
    title: "Your session has expired",
    text:
      "You have exceeded the time limit for answering security questions. Please refresh the page and try again.",
    button: "Refresh"
  },
  OTP_SESSION_TIMEOUT: {
    title: "Your session has expired",
    text:
      "You have exceeded the time limit for submitting the OTP. Please refresh the page and try again.",
    button: "Refresh"
  },
  OTP_INVALID: {
    title: "OTP code is incorrect",
    text: "Please click the button below and try again.",
    button: "Back"
  },
  OTP_SKIP_NO_QUESTION: {
    title: "Check your information",
    text:
      "We are having trouble validating your information. Please check your information and try again.",
    button: "Back"
  },
  CHANGE_PASSWORD_EMAIL_SENT: {
    title: "Email sent",
    text:
      "Please check your emails for a link to securely change your password.",
    button: "Close"
  }
}

export function sweetAlert(
  preset = ALERT_PRESET.INFO,
  options = {},
  promise = Function
) {
  return swal({
    ...preset,
    ...options
  }).then(promise)
}

export const OPEN_SWEET_ALERT = "OPEN_SWEET_ALERT"
export const openSweetAlert = ({ ...props }) => ({
  type: OPEN_SWEET_ALERT,
  ...props
})
