/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux-immutable"
import { fromJS } from "immutable"
import { LOCATION_CHANGE } from "connected-react-router"
import { connectRouter } from "connected-react-router/immutable"

import languageProviderReducer from "containers/language-provider/reducer"
import ErrorReducer from "containers/error/reducer"
import LoadingReducer from "containers/loading/reducer"
import OAuthReducer from "containers/oauth/reducer"
import linkAccountSSOReducer from "containers/oauth/sso/reducer"
import OAuthClientReducer from "containers/oauth-client/page-handle-code/reducer"
import wrapperReducer, {
  profileReducer,
  propertyReducer
} from "containers/wrapper/reducer"
import SSOConfigsReducer from "containers/router/reducer"
import history from "./utils/history"
/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null
})

/**
 * Merge route into the global application state
 */
export function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload
      })
    default:
      return state
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    route: routeReducer,
    router: connectRouter(history),
    language: languageProviderReducer,
    error: ErrorReducer,
    loading: LoadingReducer,
    profile: profileReducer,
    ssoConfigs: SSOConfigsReducer,
    isOAuthRedirect: OAuthReducer,
    OAuthReducer: OAuthClientReducer,
    linkAccountSSO: linkAccountSSOReducer,
    wrapper: wrapperReducer,
    properties: propertyReducer,
    ...injectedReducers
  })
}
