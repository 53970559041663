import { fromJS } from "immutable"
import includes from "lodash/includes"
import { LOGIN_SUCCESS } from "containers/page-login/constants"
import { SIGNUP_SUCCESS } from "containers/page-signup/constants"
import { CREATE_PASSWORD_SUCCESS } from "containers/page-social-password/constants"
import {
  REDIRECT_CALL,
  SAVE_SUBDOMAIN,
  GET_PROFILE_SUCCESS,
  SOCIAL_EXIST_CALL,
  CLEAR_PROFILE,
  SAVE_INTEGRATING,
  CLEAR_INTEGRATING,
  SAVE_HAS_TEMP_USER,
  CLEAR_HAS_TEMP_USER,
  SET_PROFILE,
  GET_CONNECTED_PARTNER_SUCCESS,
  GET_BROKERAGE_BY_PARTNER_AGENT_SUCCESS,
  GET_USER_PROPERTIES_CALL,
  GET_USER_PROPERTIES_REQUEST,
  GET_USER_PROPERTIES_SUCCESS,
  GET_USER_PROPERTIES_FAILED,
  UPDATE_USER_ROLE_FOR_PROPERTY_CALL,
  UPDATE_USER_ROLE_FOR_PROPERTY_SUCCESS,
  UPDATE_USER_ROLE_FOR_PROPERTY_FAILED
} from "./constants"
import { UNLINK_PARTNER_ACCOUNT_SUCCESS } from "../page-connect-account/constants"
import { UPDATE_USER_TYPE_SUCCESS } from "../page-select-user-type/constants"
import { EDIT_PROFILE_SUCCESS } from "../page-edit-profile/constants"
import { FORCE_CHANGE_PASSWORD_SUCCESS } from "../page-create-password/constants"
import { sentUserToSentry } from "../../utils/sentry-utils"
import {
  ACCEPT_TERM_OF_USE_SUCCESS,
  IS_ACCEPTED_TERM_OF_USE_SUCCESS
} from "../page-finish-signup/constants"
// import { UPDATE_EMAIL_VERIFICATION_SUCCESS } from "../page-email-verification/constants"

export function loadSubDomain() {
  const full = window.location.host
  const parts = full.split(".")

  if (
    !includes(["www", "rentspree", "localhost:3000", "localhost"], parts[0])
  ) {
    return parts[0] || null
  }
  return null
}

export const initialState = fromJS({
  redirect: false,
  subdomain: "www",
  subdomainOrigin: loadSubDomain(),
  integrating: null
})

export const profileInitState = fromJS({
  email: "",
  firstName: "",
  image: "",
  lastName: "",
  phone: "",
  address: {
    street: "",
    city: "",
    state: "",
    zipCode: "",
    unitNumber: ""
  },
  hasPassword: false,
  hasRegisteredUserType: false,
  isTermsAccepted: false,
  isAgreementAccepted: false,
  registeredUserType: "",
  userType: "",
  isPasswordExpired: false,
  isPasswordRequired: false,
  hasTempUser: false,
  partnerConnects: [],
  isFetchedPartner: false,
  brokerInfo: {
    name: ""
  },
  isTermOfUseAccepted: false,
  isEmailVerificationRequired: false,
  isEmailVerified: false,
  licenses: [],
  brokerageAgentInformation: {},
  isRequiredSecuritySetup: false
})

export const userPropertiesInitialState = fromJS({
  data: [],
  loading: false,
  loaded: false,
  error: false
})

function wrapperReducer(state = initialState, action) {
  let nextState = state
  switch (action.type) {
    case REDIRECT_CALL:
      nextState = nextState.set("redirect", true)
      return nextState
    case SAVE_SUBDOMAIN:
      nextState = nextState.set("subdomain", action.payload)
      return nextState
    case SAVE_INTEGRATING:
      nextState = nextState.set("integrating", action.payload)
      return nextState
    case CLEAR_INTEGRATING:
      nextState = nextState.set("integrating", null)
      return nextState
    default:
      return state
  }
}

export function profileReducer(state = profileInitState, action) {
  let nextState = state
  let selectedState = null
  let payload = action.payload || {}
  payload = payload.result ? payload.result : payload
  switch (action.type) {
    // get profile from GET /me
    case GET_PROFILE_SUCCESS:
    case EDIT_PROFILE_SUCCESS:
    case SOCIAL_EXIST_CALL:
    case SET_PROFILE:
      nextState = state.merge(payload)
      sentUserToSentry(payload)
      return nextState
    case UNLINK_PARTNER_ACCOUNT_SUCCESS:
    case GET_CONNECTED_PARTNER_SUCCESS:
      nextState = state.merge({
        partnerConnects: payload,
        isFetchedPartner: true
      })
      return nextState
    case SIGNUP_SUCCESS:
      selectedState = {
        email: payload.email,
        isConfirmed: payload.isConfirmed,
        isTermsAccepted: payload.isTermsAccepted,
        source: payload.source,
        userType: payload.userType,
        registeredUserType: payload.registeredUserType,
        hasPassword: payload.hasPassword,
        hasRegisteredUserType: payload.hasRegisteredUserType,
        isEmailVerificationRequired: payload.isEmailVerificationRequired,
        isEmailVerified: payload.isEmailVerified
      }
      nextState = state.merge(selectedState)
      return nextState
    case CLEAR_PROFILE:
      selectedState = {
        email: "",
        image: "",
        firstName: "",
        lastName: ""
      }
      nextState = state.merge(selectedState)
      return nextState
    case FORCE_CHANGE_PASSWORD_SUCCESS:
    case LOGIN_SUCCESS:
      selectedState = {
        hasPassword: payload.hasPassword,
        hasRegisteredUserType: payload.hasRegisteredUserType,
        isPasswordExpired: payload.isPasswordExpired,
        isPasswordRequired: payload.isPasswordRequired,
        isEmailVerificationRequired: payload.isEmailVerificationRequired
      }
      nextState = state.merge(selectedState)
      return nextState
    case UPDATE_USER_TYPE_SUCCESS:
      payload.hasRegisteredUserType = true
      nextState = state.merge(payload)
      return nextState
    case CREATE_PASSWORD_SUCCESS:
      payload.hasPassword = true
      nextState = state.merge(payload)
      return nextState
    case IS_ACCEPTED_TERM_OF_USE_SUCCESS:
      nextState = nextState.set("isTermOfUseAccepted", action.payload)
      return nextState
    case GET_BROKERAGE_BY_PARTNER_AGENT_SUCCESS:
      nextState = nextState.set("brokerageAgentInformation", action.payload)
      return nextState
    case ACCEPT_TERM_OF_USE_SUCCESS:
      nextState = nextState.set("isTermOfUseAccepted", true)
      return nextState
    case SAVE_HAS_TEMP_USER:
      payload.hasTempUser = true
      nextState = state.merge(payload)
      return nextState
    case CLEAR_HAS_TEMP_USER:
      payload.hasTempUser = false
      nextState = state.merge(payload)
      return nextState
    default:
      return state
  }
}

export function propertyReducer(
  state = userPropertiesInitialState,
  { type, payload }
) {
  let newState
  switch (type) {
    case GET_USER_PROPERTIES_CALL:
    case GET_USER_PROPERTIES_REQUEST:
      newState = {
        data: [],
        loaded: false,
        loading: true,
        error: false
      }
      break
    case GET_USER_PROPERTIES_SUCCESS:
      newState = {
        data: payload,
        loaded: true,
        loading: false,
        error: false
      }
      break
    case GET_USER_PROPERTIES_FAILED:
      newState = {
        data: [],
        loaded: false,
        loading: false,
        error: true
      }
      break

    case UPDATE_USER_ROLE_FOR_PROPERTY_CALL:
      newState = {
        data: state?.toJS()?.data || [],
        loaded: false,
        loading: true,
        error: false,
        errorDetail: {}
      }
      break
    case UPDATE_USER_ROLE_FOR_PROPERTY_SUCCESS: {
      let noneMatch = true
      let updatedProperties = (state?.toJS()?.data || []).map(property => {
        // eslint-disable-next-line no-underscore-dangle
        if (payload?._id === property?._id) {
          noneMatch = false
          return payload
        }
        return property
      })
      if (noneMatch) {
        updatedProperties = [payload]
      }
      newState = {
        data: updatedProperties,
        loaded: true,
        loading: false,
        error: false,
        errorDetail: {}
      }
      break
    }
    case UPDATE_USER_ROLE_FOR_PROPERTY_FAILED:
      newState = {
        data: state?.toJS()?.data || [],
        loaded: false,
        loading: false,
        error: true,
        errorDetail: payload
      }
      break

    default:
      return state
  }
  return state.merge(newState)
}

export default wrapperReducer
