import { OPEN_SWEET_ALERT, sweetAlert } from "../libs/sweet-alert"

const sweetAlertMiddleware = () => next => action => {
  switch (action.type) {
    case OPEN_SWEET_ALERT:
      return sweetAlert(action.preset, action.option, action.promise)
    default:
  }
  next(action)
  return null
}

export default sweetAlertMiddleware
