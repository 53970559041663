import React from "react"
import styled from "styled-components"

import Panel from "@rentspree/component-v2/dist/panel"

import { TOAST_POSITION } from "containers/toast/constants"

const { BOTTOM_LEFT, BOTTOM_CENTER, BOTTOM_RIGHT } = TOAST_POSITION

const toastItemAnimationFromPosition = position => {
  switch (position) {
    case BOTTOM_LEFT:
    case BOTTOM_CENTER:
    case BOTTOM_RIGHT:
      return `
        0% {
          opacity: 0;
          transform: translateY(+100%);
        }

        10% {
          opacity: 1;
          transform: translateY(0%);
        }
      `
    // any top position
    default:
      return `
        0% {
          opacity: 0;
          transform: translateY(-100%);
        }

        10% {
          opacity: 1;
          transform: translateY(0%);
        }
      `
  }
}

const StyledToast = styled(Panel)`
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);
  font-family: “Source Sans Pro”, sans-serif;

  ${({ timeOut }) => `
    animation: fade ${timeOut / 1000}s linear forwards;
    transition: all ${timeOut / 1000}s ease-out;
    `} @keyframes fade {
    ${({ position }) => toastItemAnimationFromPosition(position)} 90% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
`

const Toast = ({
  status = "info",
  titleMessage,
  bodyMessage,
  timeOut = 3000,
  onClose
}) => {
  React.useEffect(() => {
    if (onClose && timeOut) {
      const timer = setTimeout(onClose, timeOut)
      return () => clearTimeout(timer)
    }
    return () => {}
  }, [])

  return (
    <StyledToast
      timeOut={timeOut}
      status={status}
      titleMessage={titleMessage}
      bodyMessage={bodyMessage}
      onClose={onClose}
    />
  )
}

export default Toast
