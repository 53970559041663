import { createSelector } from "reselect"
import { SSOConfigsInitState } from "./reducer"

export const selectSSOConfigs = state =>
  state.get("ssoConfigs", SSOConfigsInitState)

export const makeSelectSSOConfigs = () =>
  createSelector(selectSSOConfigs, state => state.toJS())

export const makeSelectSSOConfigsList = () =>
  createSelector(selectSSOConfigs, state => state.get("list").toJS())

export const makeSelectSSOConfigsReady = () =>
  createSelector(selectSSOConfigs, state => state.get("isReady"))

export const selectSSODetails = state =>
  state
    .get("ssoConfigs", SSOConfigsInitState)
    .get("list")
    .toJS()
