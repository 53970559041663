export const LISTING_PARTNER = {
  RENTALS: "rentals",
  TRANSACTIONDESK: "transactiondesk"
}

export const LISTING_CHANGE_SUBDOMAIN = [
  {
    from: "bhglaar",
    to: "glar"
  }
]
