import styled, { css } from "styled-components"
import { COLOR } from "styles/settings"

export const Span = styled.span`
  color: ${props => (props.color ? props.color : "inherit")};
  ${props => props.bold && `font-weight: bold`};
  ${props => props.semiBold && `font-weight: 600`};
  ${props => props.light && `font-weight: 300`};
  ${props => props.weight && `font-weight: ${props.weight}`};
  ${props => props.opacity && `opacity: ${props.opacity}`};
  ${props => props.fontStyle && `font-style: ${props.fontStyle}`};
  ${props => props.size && `font-size: ${props.size}`};
  ${props => props.lineHeight && `line-height: ${props.lineHeight}`};
  ${props => props.leftSpace && `margin-left: ${props.leftSpace}`};
  ${props => props.rightSpace && `margin-right: ${props.rightSpace}`};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.mt && `margin-top: ${props.mt}px`};
  ${props => props.mb && `margin-bottom: ${props.mb}px`};
  ${props => props.mr && `margin-right: ${props.mr}px`};
  ${props => props.ml && `margin-left: ${props.ml}px`};
  ${props => props.m0 && "margin: 0px"};
  ${props =>
    props.mMargin && css`@media(max-width:767px) {margin: ${props.mMargin}}`};
  ${props => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${props => props.align && `text-align: ${props.align}`};
  ${props => props.mCenter && "@media(max-width:767px) {text-align: center}"};
  ${props => props.lineThrough && "text-decoration: line-through"};
  ${props => props.underline && "text-decoration: underline"};
  ${props => props.decoration && `text-decoration: ${props.decoration}`};
  ${props => props.center && "text-align: center"};
  ${props => props.letterSpacing && `letter-spacing: ${props.letterSpacing}px`};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.height && `height: ${props.height}`};
  ${props => props.width && `width: ${props.width}`};
  ${props =>
    props.tMargin && css`@media(max-width:991px) {margin: ${props.tMargin}}`};
`

export const OrDiv = styled.div`
  margin: ${props => (props.isMobileWidth ? "0" : "15px 0px 15px 0px")};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Line = styled.div`
  width: 63px;
  height: 2px;
  background-color: ${COLOR.lineGray};
`
