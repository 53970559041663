/* stylelint-disable block-no-empty */
import React from "react"
import styled, { css } from "styled-components"

import { COLOR } from "styles/settings"
import { OrSpan } from "styles/new-authentication-form"
import { Span, OrDiv, Line } from "./text-styled"

export const P = styled(Span).attrs(() => ({ as: "p" }))`
  margin: ${props => (props.margin ? props.margin : "10px 0px")};
  ${props => props.disabled && `color: ${COLOR.lineGray}`};
  ${props => props.small && `font-size: 14px`};
  @media (max-width: 991px) {
    ${props => (props.mSize ? `font-size: ${props.mSize}` : "")};
    ${props => (props.mMargin ? `margin: ${props.mMargin}` : "")};
    ${props => (props.mLineHeight ? `line-height: ${props.mLineHeight}` : "")};
  }
`

export const DivText = styled(P).attrs(() => ({ as: "div" }))``

export const Label = styled(Span).attrs(() => ({ as: "label" }))`
  margin: ${props => (props.margin ? props.margin : "10px 0px")};
  ${props =>
    props.disabled &&
    css`
      color: ${COLOR.lineGray};
      > span {
        color: ${COLOR.lightSoftRed};
      }
    `};
`

export const I = styled(Span).attrs(() => ({ as: "i" }))``

export const LineThroughText = ({ text, isNewUi, isMobileWidth }) => (
  <OrDiv isMobileWidth={isMobileWidth}>
    {isNewUi ? (
      <OrSpan isMobileWidth={isMobileWidth}>{text}</OrSpan>
    ) : (
      <>
        <Line />
        <Span color={COLOR.gray} margin="0px 10px">
          {text}
        </Span>
        <Line />
      </>
    )}
  </OrDiv>
)

export const B12 = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  ${props => props.color && `color: ${props.color}`};
  ${props => props.margin && `margin: ${props.margin}`};
`

export const S14 = styled.h4`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  ${props => props.color && `color: ${props.color}`};
  ${props => props.margin && `margin: ${props.margin}`};
`
