import React from "react"

import { Auth0Provider } from "@auth0/auth0-react"

import {
  AUTH_PROVIDER_DOMAIN,
  AUTH_APP_CLIENT_ID,
  AUTH_REDIRECT_PATH,
  AUTH_AUTHENTICATED_PATH,
  AUTH_API_IDENTIFIER,
  AUTH_APP_SCOPE,
  AUTH_COOKIE_DOMAIN
} from "../../env"
import { AuthWrapper } from "./auth-wrapper"

const onRedirectCallback = appState => {
  window.location.replace(
    `${
      window.location.origin
    }${AUTH_AUTHENTICATED_PATH}${appState.queryStrings || ""}`
  )
}

export const AuthProvider = ({ children }) => (
  <Auth0Provider
    domain={AUTH_PROVIDER_DOMAIN}
    clientId={AUTH_APP_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}${AUTH_REDIRECT_PATH}`,
      scope: AUTH_APP_SCOPE,
      audience: AUTH_API_IDENTIFIER
    }}
    useCookiesForTransactions
    cookieDomain={AUTH_COOKIE_DOMAIN}
    cacheLocation="localstorage"
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens>
    <AuthWrapper>{children}</AuthWrapper>
  </Auth0Provider>
)
