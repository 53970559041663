export const PARTNERS = {
  RENTALS: "rentals"
}

export const VARIANT = {
  RENTALS_RENTER: "rentalsRenter",
  RENTALS_LANDLORD: "rentalsLandlord"
}

export const LOGIN_METHODS = {
  PASSWORD: "password",
  FACEBOOK: "facebook",
  GOOGLE: "google"
}
