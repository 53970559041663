import { DOMAIN_NAME } from "../env"

export const STORAGE = {
  USER_TOKEN: "rentspreeUserToken",
  USER_PROFILE: "rentspreeProfile",
  OAUTH_STATE_PARAMS: "oauthStateParams",
  USER_STABLE_ID: "userStableId"
}

// DOMAIN NAME HAS TO BE OMITTED FOR SHARE COOKIE TO WORK ON LOCALHOST
export const COOKIE_OPTION =
  DOMAIN_NAME === "localhost"
    ? { path: "/" }
    : { path: "/", domain: DOMAIN_NAME }
