import get from "lodash/get"
import * as Sentry from "@sentry/browser"

export const sentUserToSentry = user => {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: get(user, "_id"),
      email: get(user, "email")
    })
  })
}

export const sentSSOErrorToSentry = data => {
  const error = get(data, "error", {})
  const caughtError = new Error()
  caughtError.message = get(error, "data.message", "Something went wrong")
  caughtError.name = get(data, "name", "Auth Web ERROR")
  const mapErr = {
    status: get(error, "status", ""),
    error: get(error, "data.error", ""),
    error_description: get(error, "data.error_description", ""),
    responseURL: get(error, "request.responseURL", "")
  }
  caughtError.details = JSON.stringify(
    {
      ...data,
      error: mapErr
    },
    null,
    "  "
  )
  Sentry.captureException(caughtError)
}
