import { SET_OAUTH_REDIRECT } from "./constants"

function oAuthReducer(state = false, action) {
  switch (action.type) {
    case SET_OAUTH_REDIRECT:
      return true
    default:
      return state
  }
}

export default oAuthReducer
