import styled, { css } from "styled-components"
import { COLOR, breakpoints } from "../settings"

export const LogoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
`

export const RentSpreeLogoWrapper2023 = styled.img`
  height: 24px;
`
export const HeaderWrapper = styled.div`
  @media (max-width: 991px) {
    padding-top: 40px;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 345px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    padding: ${props => (props.mPadding ? props.mPadding : "0")};
    align-content: center;
    justify-content: flex-start;
  }
`

export const NewUiButtonSpan = styled.div`
  ${props =>
    props.isSocial &&
    css`
      min-width: 180px;
      text-align: left;
    `};
  ${props =>
    props.isSocial &&
    props.isNewUi &&
    props.isContinueButton &&
    css`
      min-width: 200px;
    `};
  ${props =>
    props.isNewUi &&
    css`
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Poppins", sans-serif;
      font-size: ${props.isMobileWidth ? "14px" : "16px"};
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    `};
`

export const NewUiSpanCss = css`
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Poppins", sans-serif;
  color: ${COLOR.textBlack};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`

export const NewUiSpan = styled.span`
  ${props =>
    props.isNewUi &&
    css`
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Poppins", sans-serif;
      font-size: ${props.fontMd ? "16px" : "14px"};
      font-style: normal;
      font-weight: ${props.forgetPassword ? "600" : "400"};
      line-height: 16px;
    `};
`

export const OrSpan = styled.span`
  color: ${COLOR.gray};
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Poppins", sans-serif;
  font-size: ${props => (props.isMobileWidth ? "12px" : "16px")};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const FooterWrapper = styled.div`
  ${breakpoints.mobile(`
  gap: 16px;
  padding-bottom: 9px;
  `)};
`

export const EnvelopeWrapper = styled.img`
  width: 28px;
  height: 28px;
  margin: 9px 10px 9px 0;
`

export const VerticalDivider = styled.div`
  width: 1px;
  height: 19px;
  flex-shrink: 0;
  background: ${COLOR.newGray};
  margin: 0 10px;
`

export const Container = styled.div`
  @media (max-width: 991px) {
    height: ${props => props.height};
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
`
