import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import injectSaga from "utils/inject-saga"
import { getSSOConfigs } from "./actions"
import saga from "./saga"
import { makeSelectSSOConfigs } from "./selectors"

export const withSaga = injectSaga({ key: "sso-configs", saga })

const mapStateToProps = createStructuredSelector({
  ssoConfigs: makeSelectSSOConfigs()
})

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getSSOConfigs }, dispatch)
  }
}

export const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
