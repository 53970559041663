import { css } from "styled-components"

export const TOPBAR_HEIGHT = "70px"
export const LOGO_HEIGHT = "30px"
export const LOGO_HEADER_HEIGHT = "50px"
export const LOGO_HEADER_WIDTH = "320px"
export const LOGO_HEADER_HEIGHT_MOBILE = "60px"
export const MAIN_PANEL_WIDTH = "408px"
export const MAIN_PANEL_HEIGHT = "500px"
export const NEW_MAIN_PANEL_HEIGHT = "560px"
export const PROFILE_PANEL_HEIGHT = "680px"
export const LARGE_BUTTON_BORDER_RADIUS = "36px"
export const BUTTON_HEIGHT = "40px"
export const SMALL_BUTTON_PADDING = "25px"
export const SMALL_BUTTON_BORDER_RADIUS = "20px"
export const NAVBAR_HEIGHT = "70px"
export const BREADCRUMBS_HEIGHT = "45px"
export const BREADCRUMBS_HEIGHT_MOBILE = "35px"

export const MAIN_PANEL_PROFILE_WIDTH = "920px"
export const LEFT_PANEL_PROFILE_WIDTH = "190px"

/**
 * This is last our font convention according to UIKit guideline
 */
export const RSP_FONT = {
  bodySmallSize: "12px",
  bodyNormalSize: "14px",
  bodyTitleSize: "16px",
  largeTitleSize: "24px",
  normalWeight: 400,
  semiBoldWeight: 600
}

export const COLOR = {
  // background
  darkSteelBlue: "#495c74",
  steelBlue: "#688098",
  lightSteelBlue: "#e7eced",
  // facebook
  facebookBlue: "#4565a0",
  darkFacebookBlue: "#2a477c",
  // button , link to Facebook and Google sign up , Forget Password
  brightBlue: "#3b99fc",
  deepBlue: "#007ADF",
  skyBlue: "#7cd5ff",
  // hover button and link
  waterBlue: "#146dca",
  // login with different account , tell us about your self page
  brightRoyalBLue: "#4b97f5",
  // user pic background
  veryLightSteelBlue: "#c3cbdf",
  // checkbox selected
  superLightBlue: "#FAFCFF",
  lightDeepBlue: "rgba(59, 153, 252, 0.1)",
  steelBlueDark: "#303E51",
  deepBluePale: "#E2F0FF",
  // Text
  textBlack: "#333333",

  lightBlack: "#3D3D3D",

  veryLightGray: "#f1f1f1",
  lineGray: "#e5e5e5",
  lightGray: "#aaaaaa",
  gray: "#888888",
  bgGray: "#F9F9F9",
  darkGray: "#4a4a4a",
  mediumGray: "#999999",
  borderGray: "rgba(0, 0, 0, 0.23)",
  shadowGray: "rgba(0, 0, 0, 0.08)",
  textGray: "#707070",

  black: "#000000",
  white: "#ffffff",
  whiteTwo: "#fdfdfd",
  whiteThree: "#f9f9f9",

  softRed: "#f15555",
  paleRed: "#FFE9E9",
  lightSoftRed: "rgba(241, 85, 85, 0.1)",
  lightWhiteRed: "#ffe9e9",

  green: "#80c640",
  springGreen: "#b6f67b",
  darkGreen: "#67A033",
  lightWhiteGreen: "#e6ffcf",

  yellow: "#d3b84d",
  darkYellow: "#ac932d",
  lightSoftYellow: "#fef5d9",

  rentalsPartner: "#012262",
  caa: "#243572",
  shallowBluePale: "#E0FBFF",

  deepBlueDark: "#0062B3",
  primaryDarkBlue: "#2563AD",
  newDeepBlue: "#007ADF",
  greyLv2: "#5F5F5F",
  greyLv6: "#F1F2F2",

  blue60: "#195DB8",
  blueAccentA70: "#002570",
  blueAccentA50: "#004BB0",

  red20: "#fbdfdc",
  red70: "#9e302c",

  bodyTextNeutral90: "#222222",
  newGray: "#cfcfcf",
  grayScale15: "#ebebeb",
  googleYellow: "#f29900",
  trustPilotGreen: "#00b67a",

  greyBorder: "#e0e0e0",
  greyHr: "#d9d9d9",
  backgroundNeutral20: "#f9f9ff",

  Neutral90: "#434343",
  primaryLabelText: "#1F15E1"
}

export const MAIN_PANEL_SHADOW = "0 5px 20px 0 rgba(0, 0, 0, 0.23)"
export const LEFT_PANEL_PROFILE_SHADOW = "0 5px 20px 0 rgba(0, 0, 0, 0.05)"
export const FOOTER_SHADOW_MOBILE = "0 0 20px 0 rgba(0, 0, 0, 0.23)"
export const CHECKBOX_NORMAL_SHADOW = "0 2px 7px 0 rgba(0, 0, 0, 0.1)"
export const CHECKBOX_HOVER_SHADOW = "0 2px 15px 0 rgba(0, 0, 0, 0.25)"

export const ANIMATE = {
  FAST: "0.2s",
  NORMAL: "0.5s",
  SLOW: "1s"
}

export const FLEX_CENTER = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LINE_HEIGHT_BY_SIZE = {
  "14px": "20px",
  "16px": "22px",
  "36px": "45px"
}

export const breakpoints = {
  mobile: styles => `
    @media (max-width: 991px) {
      ${styles}
    }
  `,
  mobileContact: styles => `
  @media (max-width: 1223px) {
    ${styles}
  }
`
}
