import React from "react"
import styled, { css } from "styled-components"
import { compose } from "redux"
import { withRouter } from "react-router"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import includes from "lodash/includes"
import CloseIcon from "@mui/icons-material/Close"

import { COLOR } from "styles/settings"
import { SOURCE, pathErrorWithoutModal } from "constants/source"
import { RouterLink, Link, Button } from "components/buttons"
import { Span } from "components/typography/text-styled"
import { ROUTE } from "containers/router/constants"
import { ERROR_TYPE } from "containers/error/constants"
import { FormattedMessage as Message } from "react-intl"
import { withConnect } from "./connect"
import { ALERT_MESSAGE } from "../../libs/sweet-alert"
import { buildErrorSweetAlertProperties } from "../../utils/sweet-alert-utils"
import {
  ERROR_VALIDATION_CODE,
  ERROR_CODE
} from "../../constants/error-messages"

export const OldErrorWrapper = css`
  display: flex;
  height: auto;
  background-color: ${COLOR.softRed};
  color: ${COLOR.white};
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  min-height: 50px;
`

export const NewErrorWrapper = css`
  display: flex;
  height: auto;
  background-color: ${COLOR.red20};
  color: ${COLOR.red70};
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  min-height: 50px;
  text-align: center;
  border-radius: 8px;
  margin: 0 52px 10px 52px;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;

  & a {
    color: ${COLOR.red70};
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  & a:hover {
    color: ${COLOR.red70}ee;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  @media (max-width: 991px) {
    margin: 0;
    border-radius: 0;
    max-width: unset;
  }
`

export const ErrorWrapper = styled.div`
  ${props => (props.isNewUi ? NewErrorWrapper : OldErrorWrapper)};
`

export const OldErrorText = css`
  font-size: 18px;
  text-align: left;
  margin-bottom: 0;
`
export const NewErrorText = css`
  text-align: left;
  margin-bottom: 0;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

export const ErrorText = styled.p`
  ${props => (props.isNewUi ? NewErrorText : OldErrorText)};
`

export const CloseButton = props => (
  <Button
    id="closeError"
    onClick={props.actions.closeError}
    opacity="0.75"
    hoverOpacity="1">
    <CloseIcon fontSize="20px" />
  </Button>
)

export const NetworkError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>
      An unknown network error has occurred. Please try again later.
    </ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const ConflictError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>
      Please try again using the authentication method you used during sign up.
    </ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const TokenError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>
      Unable to create new password. Please try again with another password
      reset link
    </ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const InvalidCredentialError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>
      Your email or password is incorrect.
      {props.isOAuthRedirect ? (
        <Span>&nbsp;Please try again</Span>
      ) : (
        <Span>
          <br />Please try again or&nbsp;
          <RouterLink
            to={ROUTE.FORGET_PASSWORD}
            search={props.location.search}
            size="18px"
            align="right"
            color="white"
            hovercolor="white"
            underline
            opacity="0.75"
            hoverOpacity="1">
            <Span bold>reset your password</Span>
          </RouterLink>
        </Span>
      )}
    </ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const InvalidEmailError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>Invalid email address</ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const InvalidPasswordError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>Invalid password</ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const UnauthorizedError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>
      An error occur, please try again
    </ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const EmailNotExistError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>
      Please contact your MLS to add an email address to your account. If you
      have any questions, please contact us at support@rentspree.com.
    </ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const DefaultError = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>{props.message}</ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const CSRFAttacks = props => (
  <ErrorWrapper isNewUi={props.isNewUi}>
    <ErrorText isNewUi={props.isNewUi}>
      We couldn&apos;t log you in. Please try again.
      <Span>
        <br />
        <Link
          href={ROUTE.CONTACT_US}
          size="18px"
          align="left"
          color="white"
          hovercolor="white"
          underline
          opacity="0.75"
          hoverOpacity="1"
          target="_blank">
          <Span bold>Contact us</Span>
        </Link>&nbsp;if this message continues.
      </Span>
    </ErrorText>
    <CloseButton actions={props.actions} />
  </ErrorWrapper>
)

export const RenderErrorCode = ({ errors, code, values, ...props }) => {
  if (errors[code]) {
    const msg = <Message {...errors[code]} values={values} />
    return <DefaultError message={msg} {...props} />
  }
  return null
}

export const isIntegrating = (ssoDetails, integrating) =>
  ssoDetails.some(sso => sso.subdomain === integrating && sso.isIntegrating)

export const ErrorMessage = ({
  error,
  actions,
  location,
  isOAuthRedirect,
  ssoDetails,
  isNewUi = false
}) => {
  let errorMessage = null
  if (error) {
    const emailError = get(error, "data.errors.email", {})
    const errCode = get(error, "data.code")
    const hasEmailError = !isEmpty(emailError)
    switch (error.status) {
      case 401:
        if (
          error.source === SOURCE.LOGIN &&
          includes(pathErrorWithoutModal, location.pathname)
        ) {
          errorMessage = (
            <InvalidCredentialError
              actions={actions}
              location={location}
              isOAuthRedirect={isOAuthRedirect}
              isNewUi={isNewUi}
            />
          )
        } else if (
          error.source === SOURCE.SSO_LOGIN &&
          includes(pathErrorWithoutModal, location.pathname)
        ) {
          errorMessage = (
            <UnauthorizedError actions={actions} isNewUi={isNewUi} />
          )
        } else {
          actions.openSweetAlert(
            buildErrorSweetAlertProperties(
              error,
              ALERT_MESSAGE.UNAUTHORIZED_ERROR
            )
          )
        }
        break
      case 422:
        if (error.source === SOURCE.EDIT_MAILING_ADDRESS) {
          errorMessage = (
            <DefaultError
              message={error.message}
              actions={actions}
              isNewUi={isNewUi}
            />
          )
        } else if (hasEmailError) {
          errorMessage = (
            <InvalidEmailError actions={actions} isNewUi={isNewUi} />
          )
        } else if (errCode) {
          errorMessage = (
            <RenderErrorCode
              errors={ERROR_VALIDATION_CODE}
              code={errCode}
              actions={actions}
              isNewUi={isNewUi}
            />
          )
        } else {
          errorMessage = (
            <InvalidPasswordError actions={actions} isNewUi={isNewUi} />
          )
        }
        break
      case 409:
        errorMessage = <ConflictError actions={actions} isNewUi={isNewUi} />
        break
      case 400:
      case 500:
        if (
          !isEmpty(error.data) &&
          (error.data.message === "Invalid token" ||
            error.data.message === "Token is expired")
        )
          errorMessage = <TokenError actions={actions} isNewUi={isNewUi} />
        else errorMessage = <NetworkError actions={actions} isNewUi={isNewUi} />
        break
      default:
        if (
          error.source === SOURCE.SSO_LOGIN &&
          error.type === ERROR_TYPE.OAUTH_STATE_ERROR
        ) {
          errorMessage = (
            <CSRFAttacks
              actions={actions}
              isLinkAccount={error.isLinkAccount}
              isNewUi={isNewUi}
            />
          )
        } else if (error.source === SOURCE.SSO_LOGIN) {
          if (!isIntegrating(ssoDetails, get(error, "data.integrating", ""))) {
            if (get(error, "data.error") === ERROR_CODE.EMAIL_NOT_EXISTS) {
              errorMessage = (
                <EmailNotExistError actions={actions} isNewUi={isNewUi} />
              )
            } else {
              errorMessage = (
                <UnauthorizedError actions={actions} isNewUi={isNewUi} />
              )
            }
          }
        } else if (error.source === SOURCE.LINK_ACCOUNT) {
          errorMessage = (
            <DefaultError
              message={error.message}
              actions={actions}
              isNewUi={isNewUi}
            />
          )
        } else {
          errorMessage = <NetworkError actions={actions} isNewUi={isNewUi} />
        }
        break
    }
  }
  return <React.Fragment>{errorMessage}</React.Fragment>
}

export default compose(
  withConnect,
  withRouter
)(ErrorMessage)
