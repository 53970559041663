import Link from "@rentspree/component-v2/dist/button/link"
import { COLOR } from "styles/settings"
import styled from "styled-components"

// this because there was "body a {text-decoration: none !important}" from styledComponent
export const StyledLink = styled(Link)`
  text-decoration: underline !important;
`

export const LoadingSpin = styled.img`
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
export const CardElementWrapper = styled.div`
  border-radius: 5px;
  background: ${COLOR.bgGray};
  padding: 15px;
  height: 102px;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    height: auto;
  }
`

// prop isSelectedCard: difference of padding and padding-bottom 2px are for border
export const CardRadioElementWrapper = styled.div`
  box-sizing: border-box;
  background: ${props =>
    (props.isCardDeclined && COLOR.paleRed) ||
    (props.isSelectedCard && COLOR.deepBluePale) ||
    COLOR.bgGray};
  border: ${props =>
    (props.isCardDeclined && `2px solid ${COLOR.softRed}`) ||
    (props.isSelectedCard && `2px solid ${COLOR.brightBlue}`)};
  padding: ${props => (props.isSelectedCard ? "13px" : "15px")};
  ${props =>
    props.isSavedCard &&
    `padding-bottom: ${props.isSelectedCard ? "18px" : "20px"}`};
  height: 102px;
  margin-bottom: 20px;
  border-radius: 5px;
  @media (max-width: 991px) {
    height: auto;
  }
`
export const CardElementContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const CardElementInputBox = styled.div`
  background: ${props => props.background || COLOR.white};
  padding: 9px 15px;
  border: 1px solid ${props => props.border};
  margin: 0;
  @media (max-width: 991px) {
    margin: 0 0 10px 0;
    :last-child {
      margin: 0;
    }
  }
`
export const Logo = styled.img`
  width: 140px;
  height: 30px;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
`

export const SavedCardInformation = styled.div`
  display: flex;
  align-items: center;
`

export const StyledCardInformationImage = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
  margin-right: ${props => props.marginRight || "15px"};
`
export const RadioLabel = styled.label`
  display: flex;
  padding: 0;
  margin: 0;
  font-weight: normal;
  cursor: pointer;
  width: 100%;
  transition: all, 0.5s;
  > input[type="radio"]:checked {
    ~ .radioIcon {
      .radioCircle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: ${props =>
          props.isCardDeclined ? COLOR.softRed : COLOR.brightRoyalBLue};
        background-color: ${props =>
          props.isCardDeclined ? COLOR.softRed : COLOR.brightRoyalBLue};
        &:after {
          content: "";
          width: 6px;
          height: 6px;
          background: ${COLOR.white};
          border-radius: 50px;
          display: block;
        }
      }
    }
  }
`
export const RadioIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  margin-right: 14px;
`
export const RadioCircle = styled.span`
  background: ${COLOR.white};
  width: 18px;
  height: 18px;
  border-radius: 50px;
  border: 1px solid ${COLOR.lightGray};
`
export const RadioInput = styled.input`
  position: absolute;
  cursor: pointer;
  opacity: 0;
`

export const DetailRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`

export const BillingDateRow = styled(DetailRow)`
  margin: 0 0 10px 0;
`

export const RemoveCoupon = styled.button`
  padding: 0;
  width: fit-content;
  white-space: nowrap;
  font-size: 14px;
  align-self: flex-end;
  text-decoration: underline;
  font-weight: 600;
`

export const NumberWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

export const SplitLineCondition = styled.div`
  display: inline;
  @media (max-width: 991px) {
    display: block;
  }
`

export const SplitLineTrialCondition = styled.div`
  display: block;
  @media (max-width: 991px) {
    display: inline;
  }
`
