import { fromJS } from "immutable"

import {
  LINK_ACCOUNT_REQUEST,
  LINK_ACCOUNT_SUCCESS,
  LINK_ACCOUNT_FAILED,
  LINK_ACCOUNT_CLEAR
} from "./constants"

export const initialState = fromJS({
  status: "",
  data: {}
})

function linkAccountSSOReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case LINK_ACCOUNT_REQUEST:
      nextState = state.set("status", "REQUEST")
      nextState = nextState.set("data", action.payload)
      return nextState
    case LINK_ACCOUNT_SUCCESS:
      nextState = state.set("status", "SUCCESS")
      nextState = nextState.set("data", action.payload)
      return nextState
    case LINK_ACCOUNT_FAILED:
      nextState = state.set("status", "FAILED")
      nextState = nextState.set("data", action.payload)
      return nextState
    case LINK_ACCOUNT_CLEAR:
      return initialState
    default:
      return state
  }
}
export default linkAccountSSOReducer
