export const CREATE_NEW_PASSWORD_CALL = "CREATE_NEW_PASSWORD_CALL"

export const createNewPasswordCall = password => ({
  type: CREATE_NEW_PASSWORD_CALL,
  password
})

export const FORCE_CHANGE_PASSWORD_REQUEST = "FORCE_CHANGE_PASSWORD_REQUEST"
export const FORCE_CHANGE_PASSWORD_FAILED = "FORCE_CHANGE_PASSWORD_FAILED"
export const FORCE_CHANGE_PASSWORD_SUCCESS = "FORCE_CHANGE_PASSWORD_SUCCESS"

export const forceChangePasswordRequest = payload => ({
  type: FORCE_CHANGE_PASSWORD_REQUEST,
  payload
})
export const forceChangePasswordFailed = payload => ({
  type: FORCE_CHANGE_PASSWORD_FAILED,
  payload
})
export const forceChangePasswordSuccess = payload => ({
  type: FORCE_CHANGE_PASSWORD_SUCCESS,
  payload
})
