import { all, call, put, takeLatest } from "@redux-saga/core/effects"
import { GET_SSO_CONFIGS_URL } from "constants/api-url"
import { ALERT_MESSAGE, openSweetAlert } from "libs/sweet-alert"
import { userApiInstance } from "utils/axios-utils"
import { buildErrorSweetAlertProperties } from "utils/sweet-alert-utils"
import {
  getSSOConfigsFailed,
  getSSOConfigsRequest,
  getSSOConfigsSuccess,
  GET_SSO_CONFIGS_CALL
} from "./constants"

/**
 * API caller
 */

export const getSSOConfigsApi = () => userApiInstance.get(GET_SSO_CONFIGS_URL)

/**
 * Worker
 */

export function* getSSOConfigs() {
  yield put(getSSOConfigsRequest())
  try {
    const response = yield call(getSSOConfigsApi)
    yield put(getSSOConfigsSuccess(response))
  } catch (err) {
    yield put(getSSOConfigsFailed(err))
    yield put(
      openSweetAlert(
        buildErrorSweetAlertProperties(err, ALERT_MESSAGE.GENERAL_ERROR)
      )
    )
  }
}

/**
 * Watcher
 */

export function* watchSSOConfigs() {
  yield takeLatest(GET_SSO_CONFIGS_CALL, getSSOConfigs)
}

export default function* rootSaga() {
  yield all([watchSSOConfigs()])
}
