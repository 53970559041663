import { CDN_URL, IMAGE_PROXY_URL } from "../env"

/**
 * Build CDN URL of image (in `static-files`)
 * @param {string} path
 * @returns {string} CDN URL of image
 */
export const getCDNImage = path => {
  if (!path) {
    return ""
  }
  return `${CDN_URL}/static-files${path.charAt(0) === "/" ? path : `/${path}`}`
}

/**
 * Sanitize size value to get only numbers
 * @param {string|number} value
 * @returns {number} Sanitized number
 */
const sanitizeSizeValue = value =>
  typeof value === "string" ? value.replace(/\D/g, "") : value

/**
 * Resize Image with Image proxy
 * @param {string} url URL of image
 * @param {object} size size of image
 * @param {string|number} size.width
 * @param {string|number} size.height
 * @returns {string} Resized URL with Image proxy
 */
export const resizeWithImageProxy = (url, size) => {
  if (!url) {
    return ""
  }
  if (size) {
    let resizedUrl = `${IMAGE_PROXY_URL}/resize`
    if (size.width) {
      resizedUrl = resizedUrl.concat(`/w:${sanitizeSizeValue(size.width)}`)
    }
    if (size.height) {
      resizedUrl = resizedUrl.concat(`/h:${sanitizeSizeValue(size.height)}`)
    }
    return resizedUrl.concat(`/plain/${url}`)
  }
  return url
}
