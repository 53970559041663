import get from "lodash/get"
import { buildPath, query } from "@rentspree/path"
import { BASE_NAME, MAIN_URL } from "env"
import { ROUTE } from "containers/router/constants"
import { locationReplace } from "utils/call-window"

export const getCurrentUrl = (
  location = {},
  subdomain,
  basePath = BASE_NAME
) => {
  let subdomainMainUrl = MAIN_URL
  if (subdomain && subdomain !== "rentspree")
    subdomainMainUrl = subdomainMainUrl.replace("www", subdomain)
  return (
    subdomainMainUrl +
    basePath +
    get(location, "pathname", "") +
    get(location, "search", "")
  )
}

export const redirectToLogin = (
  location = {},
  subdomain,
  continueBasePath = BASE_NAME
) => {
  const currentUrl = getCurrentUrl(location, subdomain, continueBasePath)
  const search = query.parse(location.search)
  const loginUrl = buildPath(
    MAIN_URL + BASE_NAME + ROUTE.LOGIN,
    {},
    { ...search, continue: currentUrl }
  )
  locationReplace(loginUrl)
}

export const getLoginUrl = () => buildPath(MAIN_URL + BASE_NAME + ROUTE.LOGIN)
