export const ACCEPT_TERM_OF_USE_CALLED = "ACCEPT_TERM_OF_USE_CALLED"
export const ACCEPT_TERM_OF_USE_REQUEST = "ACCEPT_TERM_OF_USE_REQUEST"
export const ACCEPT_TERM_OF_USE_SUCCESS = "ACCEPT_TERM_OF_USE_SUCCESS"
export const ACCEPT_TERM_OF_USE_FAILED = "ACCEPT_TERM_OF_USE_FAILED"

export const IS_ACCEPTED_TERM_OF_USE_CALLED = "IS_ACCEPTED_TERM_OF_USE_CALLED"
export const IS_ACCEPTED_TERM_OF_USE_REQUEST = "IS_ACCEPTED_TERM_OF_USE_REQUEST"
export const IS_ACCEPTED_TERM_OF_USE_SUCCESS = "IS_ACCEPTED_TERM_OF_USE_SUCCESS"
export const IS_ACCEPTED_TERM_OF_USE_FAILED = "IS_ACCEPTED_TERM_OF_USE_FAILED"

export const isAcceptedTermOfUseCall = () => ({
  type: IS_ACCEPTED_TERM_OF_USE_CALLED
})
export const isAcceptedTermOfUseRequest = () => ({
  type: IS_ACCEPTED_TERM_OF_USE_REQUEST
})
export const isAcceptedTermOfUseSuccess = payload => ({
  type: IS_ACCEPTED_TERM_OF_USE_SUCCESS,
  payload
})
export const isAcceptedTermOfUseFailed = payload => ({
  type: IS_ACCEPTED_TERM_OF_USE_FAILED,
  payload
})

export const acceptTermOfUseCall = payload => ({
  type: ACCEPT_TERM_OF_USE_CALLED,
  payload
})
export const acceptTermOfUseRequest = () => ({
  type: ACCEPT_TERM_OF_USE_REQUEST
})
export const acceptTermOfUseSuccess = () => ({
  type: ACCEPT_TERM_OF_USE_SUCCESS
})
export const acceptTermOfUseFailed = payload => ({
  type: ACCEPT_TERM_OF_USE_FAILED,
  payload
})

export const PARTNER_REQUIRES_USER_TYPE_SELECTION = [
  "caa",
  "rent",
  "rentals",
  "apartmentlist"
]

export const TERM_OF_USE_AGREEMENT = "term-of-use"

export const FINISH_SIGNUP_SOURCE = "finish-signup"

export const RSP_TERMS_OF_SERVICE = "https://rentspree.com/terms-of-service"

export const RSP_PRIVACY_POLICY =
  "https://rentspree.com/rentspree-privacy-policy"

export const EVENT_LOCATION = {
  CONFIRM_PAGE: "confirm page",
  SECONDARY_CTA: "secondary cta",
  CTA: "cta"
}

export const EVENT_MAIN_FEATURE = "sign up"

export const EVENT_PAGE_NAME = "partner sso"

export const CONFIRM_BUTTON_TEXT = "Confirm"

// TODO: Remove this when we remove partner_sso_page_revision feature flag
export const USE_DIFFERENT_EMAIL_TEXT = "I want to use a different email"
