/*
 *
 * wrapper constants
 *
 */

export const REDIRECT_CALL = "REDIRECT_CALL"
export const SAVE_SUBDOMAIN = "SAVE_SUBDOMAIN"
export const GET_PROFILE_CALL = "GET_PROFILE_CALL"
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST"
export const GET_PROFILE_REQUEST_WITH_NO_LOADING_PANEL =
  "GET_PROFILE_REQUEST_WITH_NO_LOADING_PANEL"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED"
export const SOCIAL_EXIST_CALL = "SOCIAL_EXIST_CALL"
export const CLEAR_PROFILE = "CLEAR_PROFILE"
// Auth0 Migration
export const POST_LOGIN_REDIRECT = "POST_LOGIN_REDIRECT"
export const REDIRECT_SELECTOR = "REDIRECT_SELECTOR"
export const SAVE_INTEGRATING = "SAVE_INTEGRATING"
export const CLEAR_INTEGRATING = "CLEAR_INTEGRATING"
export const SAVE_HAS_TEMP_USER = "SAVE_HAS_TEMP_USER"
export const CLEAR_HAS_TEMP_USER = "CLEAR_HAS_TEMP_USER"
export const LOCATION_TO_CONTINUE_CALL = "LOCATION_TO_CONTINUE_CALL"
export const SET_PROFILE = "SET_PROFILE"
export const GET_CONNECTED_PARTNER_CALL = "GET_CONNECTED_PARTNER_CALL"
export const GET_CONNECTED_PARTNER_REQUEST = "GET_CONNECTED_PARTNER_REQUEST"
export const GET_CONNECTED_PARTNER_SUCCESS = "GET_CONNECTED_PARTNER_SUCCESS"
export const GET_CONNECTED_PARTNER_FAILED = "GET_CONNECTED_PARTNER_FAILED"

export const GET_BROKERAGE_BY_PARTNER_AGENT_REQUEST =
  "GET_BROKERAGE_BY_PARTNER_AGENT_REQUEST"
export const GET_BROKERAGE_BY_PARTNER_AGENT_SUCCESS =
  "GET_BROKERAGE_BY_PARTNER_AGENT_SUCCESS"
export const GET_BROKERAGE_BY_PARTNER_AGENT_FAILED =
  "GET_BROKERAGE_BY_PARTNER_AGENT_FAILED"

export const callLocationToContinue = defaultUrl => ({
  type: LOCATION_TO_CONTINUE_CALL,
  defaultUrl
})
export const callRedirect = () => ({ type: REDIRECT_CALL })
export const callSaveSubdomain = payload => ({ type: SAVE_SUBDOMAIN, payload })
export const getProfileCall = payload => ({ type: GET_PROFILE_CALL, payload })
export const getProfileRequest = payload => ({
  type: GET_PROFILE_REQUEST,
  payload
})
export const getProfileRequestWithNoLoadingPanel = () => ({
  type: GET_PROFILE_REQUEST_WITH_NO_LOADING_PANEL
})
export const getProfileSuccess = payload => ({
  type: GET_PROFILE_SUCCESS,
  payload
})
export const getProfileFailed = payload => ({
  type: GET_PROFILE_FAILED,
  payload
})
export const getConnectedPartnerCall = () => ({
  type: GET_CONNECTED_PARTNER_CALL
})
export const getConnectedPartnerSuccess = payload => ({
  type: GET_CONNECTED_PARTNER_SUCCESS,
  payload
})
export const getConnectedPartnerFailed = payload => ({
  type: GET_CONNECTED_PARTNER_SUCCESS,
  payload
})
export const getConnectedPartnerRequest = payload => ({
  type: GET_CONNECTED_PARTNER_REQUEST,
  payload
})

export const callSocialExist = payload => ({
  type: SOCIAL_EXIST_CALL,
  payload
})

export const profileClear = () => ({ type: CLEAR_PROFILE })

// Auth0 Migration
export const callPostLoginRedirect = () => ({ type: POST_LOGIN_REDIRECT })
export const callRedirectSelector = () => ({ type: REDIRECT_SELECTOR })

export const saveIntegrating = payload => ({ type: SAVE_INTEGRATING, payload })
export const clearIntegrating = () => ({ type: CLEAR_INTEGRATING })

export const callSaveHasUserTemp = () => ({
  type: SAVE_HAS_TEMP_USER
})

export const callClearHasUserTemp = () => ({
  type: CLEAR_HAS_TEMP_USER
})

export const getBrokerageByPartnerAgentRequest = () => ({
  type: GET_BROKERAGE_BY_PARTNER_AGENT_REQUEST
})
export const getBrokerageByPartnerAgentSuccess = payload => ({
  type: GET_BROKERAGE_BY_PARTNER_AGENT_SUCCESS,
  payload
})
export const getBrokerageByPartnerAgentFailed = payload => ({
  type: GET_BROKERAGE_BY_PARTNER_AGENT_FAILED,
  payload
})

export const GET_USER_PROPERTIES_CALL = "GET_USER_PROPERTIES_CALL"
export const GET_USER_PROPERTIES_REQUEST = "GET_USER_PROPERTIES_REQUEST"
export const GET_USER_PROPERTIES_SUCCESS = "GET_USER_PROPERTIES_SUCCESS"
export const GET_USER_PROPERTIES_FAILED = "GET_USER_PROPERTIES_FAILED"

export const getUserPropertiesCall = payload => ({
  type: GET_USER_PROPERTIES_CALL,
  payload
})
export const getUserPropertiesRequest = () => ({
  type: GET_USER_PROPERTIES_REQUEST
})
export const getUserPropertiesSuccess = payload => ({
  type: GET_USER_PROPERTIES_SUCCESS,
  payload
})
export const getUserPropertiesFailed = payload => ({
  type: GET_USER_PROPERTIES_FAILED,
  payload
})

export const UPDATE_USER_ROLE_FOR_PROPERTY_CALL =
  "UPDATE_USER_ROLE_FOR_PROPERTY_CALL"
export const UPDATE_USER_ROLE_FOR_PROPERTY_SUCCESS =
  "UPDATE_USER_ROLE_FOR_PROPERTY_SUCCESS"
export const UPDATE_USER_ROLE_FOR_PROPERTY_FAILED =
  "UPDATE_USER_ROLE_FOR_PROPERTY_FAILED"

export const updateUserRoleForPropertyCall = payload => ({
  type: UPDATE_USER_ROLE_FOR_PROPERTY_CALL,
  payload
})
export const updateUserRoleForPropertySuccess = payload => ({
  type: UPDATE_USER_ROLE_FOR_PROPERTY_SUCCESS,
  payload
})
export const updateUserRoleForPropertyFailed = payload => ({
  type: UPDATE_USER_ROLE_FOR_PROPERTY_FAILED,
  payload
})
