import { createBrowserHistory } from "history"
import { BASE_NAME } from "../env"

const history = createBrowserHistory({
  basename: BASE_NAME
})
export const historyForceRefresh = createBrowserHistory({
  basename: BASE_NAME,
  forceRefresh: true
})

export default history
