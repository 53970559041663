import { fromJS } from "immutable"
import { LOGIN_FAILED } from "../page-login/constants"
import { SIGNUP_FAILED } from "../page-signup/constants"
import { RESET_PASSWORD_FAILED } from "../page-reset-password/constants"
import { ERROR_CLOSE, ERROR_SHOW } from "./constants"
import { FORGET_PASSWORD_FAILED } from "../page-forget-password/constants"
import { UPDATE_USER_TYPE_FAILED } from "../page-select-user-type/constants"
import { CREATE_PASSWORD_FAILED } from "../page-social-password/constants"
import { FORCE_CHANGE_PASSWORD_FAILED } from "../page-create-password/constants"
import { ACCEPT_TERM_OF_USE_FAILED } from "../page-finish-signup/constants"
import { RESEND_EMAIL_VERIFICATION_FAILED } from "../page-email-verification/constants"
export const initialState = fromJS(null)

function ErrorReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_FAILED:
    case LOGIN_FAILED:
    case FORGET_PASSWORD_FAILED:
    case RESET_PASSWORD_FAILED:
    case UPDATE_USER_TYPE_FAILED:
    case CREATE_PASSWORD_FAILED:
    case FORCE_CHANGE_PASSWORD_FAILED:
    case ERROR_SHOW:
    case ACCEPT_TERM_OF_USE_FAILED:
    case RESEND_EMAIL_VERIFICATION_FAILED:
      // scroll to top for mobile to visualize error
      window.scrollTo(0, 0)
      return fromJS({ ...action.payload })
    case ERROR_CLOSE:
      return initialState
    default:
      return state
  }
}

export default ErrorReducer
