import qs from "qs"
import isEmpty from "lodash/isEmpty"

export const parse = (str = "") =>
  !isEmpty(str) ? qs.parse(str.replace(/^\?/, "")) : {}

export function urlParser(url) {
  const origin = url.replace(/\b\/.*/, "")
  const host = origin.replace(/http[s]?:\/\//, "")
  const port = host.match(/:\d*/) || [""]
  const hostname = host.replace(/:.*/, "")
  const domain = hostname.match(/([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+$/) || [""]
  const subdomain = hostname.replace(/\..*/, "").replace(hostname, "")
  return {
    origin,
    hostname,
    domain: domain[0].replace(`${subdomain}.`, ""),
    subdomain,
    protocol: origin.replace(hostname, "").replace(port[0], ""),
    port: port[0].substring(1),
    paramQuery: url.replace(origin, "")
  }
}

/**
 *  This parser version it mostly relies on the URL API.
 *  But it provide the better support of subdomain, domain, fragment extractions.
 *
 *  @param {string} url to be parsed URL string
 */
export function urlParserV2(url) {
  const [urlWithoutSearch, search] = url.split("?")
  const urlObjectWithoutSearch = new URL(urlWithoutSearch)
  const domain = urlObjectWithoutSearch.host.includes("localhost")
    ? "localhost"
    : urlObjectWithoutSearch.hostname
        .split(".")
        .slice(-2)
        .join(".")

  // example: https://sub1.sub2.example.com/pathname#fragment?search=value
  const urlParts = {
    origin: urlObjectWithoutSearch.origin,
    hostname: urlObjectWithoutSearch.hostname,
    href: urlObjectWithoutSearch.href,
    port: urlObjectWithoutSearch.port,
    host: urlObjectWithoutSearch.host,

    // https:
    protocol: urlObjectWithoutSearch.protocol,

    // sub1.sub2
    subdomain: urlObjectWithoutSearch.hostname
      ?.replace(domain, "")
      ?.slice(0, -1),

    // example.com
    domain,

    // #fragment
    fragment: urlObjectWithoutSearch.hash,

    // ?search=value
    search: `?${search}` || ""
  }

  return urlParts
}
