import { useSelector } from "react-redux"
import {
  makeSelectSSOConfigsList,
  makeSelectSSOConfigsReady
} from "../../containers/router/selectors"
import { PARTNERS, VARIANT } from "../../constants/partners"
import { USER_TYPE } from "../../containers/page-select-user-type/constants"

export const useSsoConfigs = () => {
  const list = useSelector(makeSelectSSOConfigsList())
  const isReady = useSelector(makeSelectSSOConfigsReady())
  const getSsoConfig = ({ provider, userType = USER_TYPE.OWNER }) => {
    let variant = provider
    if (provider === PARTNERS.RENTALS) {
      if (userType === USER_TYPE.APPLICANT) {
        variant = VARIANT.RENTALS_RENTER
      } else {
        variant = VARIANT.RENTALS_LANDLORD
      }
    }
    const ssoDetail = list.find(sso => sso.variant === variant)
    return ssoDetail
  }

  return {
    list,
    isReady,
    getSsoConfig
  }
}
