import { createSelector } from "reselect"
import {
  initialState,
  profileInitState,
  userPropertiesInitialState
} from "./reducer"

/**
 * Direct selector to the wrapper state domain
 */

const selectWrapper = state => state.get("wrapper", initialState)

export const selectProfile = state => state.get("profile", profileInitState)

export const selectUserProperties = state =>
  state.get("properties", userPropertiesInitialState)

export const selectIsOAuthRedirect = state =>
  state.get("isOAuthRedirect", false)
export const makeSelectIsOAuthRedirect = () =>
  createSelector(selectIsOAuthRedirect, state => state)

export const getRouter = state => state.get("router")

export const getSearch = state =>
  getRouter(state)
    .get("location")
    .get("search")

/**
 * Other specific selectors
 */

/**
 * Default selector used by wrapper
 */

const makeSelectRedirect = () =>
  createSelector(selectWrapper, state => state.get("redirect"))

const makeSelectSubdomain = () =>
  createSelector(selectWrapper, state => state.get("subdomain"))

const makeSelectSubdomainOrigin = () =>
  createSelector(selectWrapper, state => state.get("subdomainOrigin"))

const makeSelectIntegrating = () =>
  createSelector(selectWrapper, state => state.get("integrating"))

const makeSelectUserId = () =>
  createSelector(selectProfile, state => state.get("_id"))

const makeSelectProfile = () =>
  createSelector(selectProfile, state => {
    if (state) {
      return state.toJS()
    }
    return null
  })

const makeSelectLocation = () =>
  createSelector(getRouter, state => {
    const location = state.get("location")
    if (location) return location.toJS()
    return {}
  })

const makeSelectUserProperties = () =>
  createSelector(selectUserProperties, state => {
    const properties = state.get("data")
    if (properties) {
      return properties.toJS()
    }
    return []
  })

const makeSelectUserPropertiesLoaded = () =>
  createSelector(selectUserProperties, state => state.get("loaded"))

export {
  selectWrapper,
  makeSelectRedirect,
  makeSelectSubdomain,
  makeSelectSubdomainOrigin,
  makeSelectIntegrating,
  makeSelectProfile,
  makeSelectLocation,
  makeSelectUserId,
  makeSelectUserProperties,
  makeSelectUserPropertiesLoaded
}
