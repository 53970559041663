import includes from "lodash/includes"

export const SIGNUP_CALL = "SIGNUP_CALL"
export const SIGNUP_REQUEST = "SIGNUP_REQUEST"
export const SIGNUP_FAILED = "SIGNUP_FAILED"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const SAVE_USER_TYPE = "SAVE_USER_TYPE"

export const signupCall = payload => ({ type: SIGNUP_CALL, payload })
export const signupRequest = payload => ({ type: SIGNUP_REQUEST, payload })
export const signupFailed = payload => ({ type: SIGNUP_FAILED, payload })
export const signupSuccess = payload => ({
  type: SIGNUP_SUCCESS,
  payload
})

export const REGISTER_USER_TYPE_MAP = {
  AGENT: "agent",
  OWNER: "landlord",
  MANAGER: "property_management",
  APPLICANT: "renter"
}

export const USER_TYPE_MAP = {
  AGENT: "landlord",
  OWNER: "landlord",
  MANAGER: "landlord",
  APPLICANT: "renter"
}

export const REGISTERED_USER_TYPE_KEY_MAP = {
  AGENT: "agent",
  LANDLORD: "owner",
  PROPERTY_MANAGEMENT: "manager",
  RENTER: "applicant"
}

export const SSO_USER_TYPE = {
  LANDLORD: "owner",
  RENTER: "applicant"
}

export const AGENT_INVITES_LANDLORDS_SIGNUP_PAGE_EXPERIMENT = {
  KEY: "agent_invites_landlords_signup_page_experiment",
  EXPERIENCE: "experience"
}

// payload = :userType(agent|landlord|property_management|renter)
export const saveUserType = payload => ({ type: SAVE_USER_TYPE, payload })
export const getRegisteredUserType = userType => {
  if (
    !userType ||
    !includes(Object.keys(REGISTER_USER_TYPE_MAP), userType.toUpperCase())
  )
    return null
  return REGISTER_USER_TYPE_MAP[userType.toUpperCase()]
}

export const getUserType = userType => {
  if (
    !userType ||
    !includes(Object.keys(REGISTER_USER_TYPE_MAP), userType.toUpperCase())
  )
    return null
  return USER_TYPE_MAP[userType.toUpperCase()]
}

export const getUserTypeKey = registeredUserType => {
  if (
    !registeredUserType ||
    !includes(
      Object.keys(REGISTERED_USER_TYPE_KEY_MAP),
      registeredUserType.toUpperCase()
    )
  ) {
    return null
  }
  return REGISTERED_USER_TYPE_KEY_MAP[registeredUserType.toUpperCase()]
}
