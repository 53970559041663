import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { LoadingWrapper } from "components/layout"
import CircularProgress from "@rentspree/component-2023.components.organisms.circular-progress"
import { getLoginUrl } from "utils/login-redirect"

export const AuthLoadingPlaceholder = ({ error }) => (
  <LoadingWrapper noneNavbar background="#FFFFFF">
    <CircularProgress size="medium" color="black" />
    {error && "Got authorization error, redirecting to the login page."}
  </LoadingWrapper>
)

const Callback = () => {
  const { error, logout } = useAuth0()
  useEffect(
    async () => {
      if (error) {
        setTimeout(
          async () =>
            logout({
              logoutParams: {
                returnTo: getLoginUrl()
              }
            }),
          5000
        )
      }
    },
    [error]
  )
  return <AuthLoadingPlaceholder error={error} />
}

export default Callback
