/* eslint-disable camelcase */
import get from "lodash/get"
import Tracker, {
  GTMTracker,
  AmplitudeTracker,
  UserRecorderTracker
} from "@rentspree/tracker"
import isEmpty from "lodash/isEmpty"
import merge from "lodash/merge"
import omitBy from "lodash/omitBy"
import Amplitude from "amplitude-js"

import {
  GOOGLE_TAG_MANAGER_CONTAINER_ID,
  AMPLITUDE_API_KEY,
  AMPLITUDE_COOKIE_DOMAIN
} from "../env"
import { generateAmplitudeProfileItem, trackerEvents } from "./tracker-const"

const userRecorderTracker = new UserRecorderTracker()

const gtmTracker = new GTMTracker({
  trackingId: GOOGLE_TAG_MANAGER_CONTAINER_ID,
  mapUserProfile: profile => ({
    id: get(profile, "_id"),
    email: get(profile, "email")
  })
})

const amplitudeTracker = new AmplitudeTracker({
  apiKey: AMPLITUDE_API_KEY,
  amplitudeSDK: Amplitude,
  amplitudeConfig: {
    domain: AMPLITUDE_COOKIE_DOMAIN,
    saveParamsReferrerOncePerSession: false,
    includeUtm: true
  },
  mapUserIdentity: profile => get(profile, "_id"),
  mapUserProfile: profile => {
    const initProfile = {
      email: generateAmplitudeProfileItem(profile.email),
      phone: generateAmplitudeProfileItem(profile.phone),
      createdAt: generateAmplitudeProfileItem(profile.createdAt, true),
      userType: generateAmplitudeProfileItem(profile.userType),
      registeredUserType: generateAmplitudeProfileItem(
        profile.registeredUserType
      )
    }
    if (!isEmpty(profile.firstName)) {
      initProfile.firstName = generateAmplitudeProfileItem(profile.firstName)
    }
    if (!isEmpty(profile.lastName)) {
      initProfile.lastName = generateAmplitudeProfileItem(profile.lastName)
    }
    return omitBy(initProfile, item => isEmpty(item.value))
  }
})

const tracker = new Tracker()

tracker.registerTracker(gtmTracker)
tracker.registerTracker(amplitudeTracker)
tracker.registerTracker(userRecorderTracker)

const trackEvent = (key, customProperties = {}) => {
  const t = get(trackerEvents, key)
  const properties = get(t, "properties", {})
  if (!isEmpty(customProperties)) {
    merge(properties, customProperties)
  }
  tracker.trackEvent(get(t, "action"), properties)
}

export {
  tracker,
  gtmTracker,
  amplitudeTracker,
  trackEvent,
  userRecorderTracker
}
