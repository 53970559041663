import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import track from "react-tracking"
import { compose } from "redux"
import { GlobalStyles } from "styles"
import { tracker } from "tracker/tracker"
import { withConnect, withSaga } from "./connect"
import { ROUTE } from "./constants"
import {
  AuthRouterLoadable,
  HandleCodePageLoadable,
  HandleTokenPageLoadable,
  OAuthLoadable,
  OnboardingRouterLoadable,
  PageNotFoundLoadable,
  PageTemporaryErrorLoadable,
  ProfileLoadable
} from "./loadable"

export function Router({ history, ...props }) {
  useEffect(() => {
    props.actions.getSSOConfigs()
  }, [])

  return (
    <>
      <GlobalStyles />
      <Switch>
        <Route path={ROUTE.PROFILE} component={ProfileLoadable} />
        <Route path={ROUTE.OAUTH} component={OAuthLoadable} />
        <Route
          exact
          path={ROUTE.TEMPORARY_ERROR}
          component={PageTemporaryErrorLoadable}
        />
        <Route exact path={ROUTE.NOT_FOUND} component={PageNotFoundLoadable} />
        <Route
          exact
          path={ROUTE.AUTH_TOKEN}
          component={HandleTokenPageLoadable}
        />
        <Route
          exact
          path={ROUTE.OAUTH_CLIENT_HANDLE_CODE}
          component={HandleCodePageLoadable}
        />
        <Route path={ROUTE.ONBOARDING} component={OnboardingRouterLoadable} />
        <Route
          path={ROUTE.ROOT}
          render={() => (
            <AuthRouterLoadable
              ssoConfigs={props.ssoConfigs}
              history={history}
            />
          )}
        />
      </Switch>
    </>
  )
}

export default compose(
  track(
    {},
    { dispatch: data => tracker.trackEvent(data.action, data.properties) }
  ),
  withSaga,
  withConnect
)(Router)
