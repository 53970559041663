import styled from "styled-components"
import React from "react"
import { Link as ReactRouterLink, NavLink } from "react-router-dom"
import LinkComponent from "@rentspree/component-2023.components.atoms.link"

import { COLOR } from "styles/settings"
import { Span } from "../typography"

export const StyledLink = styled(Span).attrs(() => ({ as: "a" }))`
  display: ${props => (props.display ? props.display : "inline-block")};
  color: ${props => (props.color ? props.color : COLOR.brightBlue)};
  ${props => props.margin && `margin: ${props.margin}`};
  &:hover,
  &:focus {
    color: ${props => (props.hovercolor ? props.hovercolor : COLOR.waterBlue)};
    ${props => props.hoverOpacity && `opacity: ${props.hoverOpacity}`};
    text-decoration: ${props =>
      props.underline || props.hoverUnderline ? true : "none"};
  }
`

export const RouterLink = ({
  text,
  children,
  search,
  to,
  useLinkComponent,
  ...rest
}) => {
  if (useLinkComponent) {
    return (
      <LinkComponent
        color="secondary"
        to={{ pathname: to, search }}
        {...rest}
        as={ReactRouterLink}>
        {text && text}
        {children && children}
      </LinkComponent>
    )
  }
  return (
    <StyledLink to={{ pathname: to, search }} {...rest} as={ReactRouterLink}>
      {text && text}
      {children && children}
    </StyledLink>
  )
}

export const RouterNavLink = ({ text, children, search, to, ...rest }) => (
  <StyledLink to={{ pathname: to, search }} {...rest} as={NavLink}>
    {text && text}
    {children && children}
  </StyledLink>
)

export const Link = ({ text, children, ...rest }) => (
  <StyledLink {...rest}>
    {text && text}
    {children && children}
  </StyledLink>
)
