/*
 *
 * PageForgetPassword constants
 *
 */

export const FORGET_WORDING = {
  title: "Reset Password",
  subTitle:
    "Enter your account email address and we'll send you a password reset link"
}

export const LINK_SEND_WORDING = {
  title: "Reset link has been sent.",
  subTitle:
    "To reset your password, please follow the instructions in the email we sent to ",
  resendLink:
    "If you cannot find the email, please check your junk/spam folder. Please wait 5 minutes before requesting a new "
}

export const FORGET_PASSWORD_CALL = "FORGET_PASSWORD_CALL"
export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED"
export const REMOVE_EMAIL_CALL = "REMOVE_EMAIL_CALL"
export const RESEND_FORGET_PASSWORD = "RESEND_FORGET_PASSWORD"

export const forgetCall = payload => ({
  type: FORGET_PASSWORD_CALL,
  payload
})

export const forgetRequest = payload => ({
  type: FORGET_PASSWORD_REQUEST,
  payload
})

export const forgetSuccess = payload => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload
})

export const forgetFailed = payload => ({
  type: FORGET_PASSWORD_FAILED,
  payload
})

export const resendForgetPassword = () => ({ type: RESEND_FORGET_PASSWORD })
export const removeEmailCall = () => ({ type: REMOVE_EMAIL_CALL })
