export function locationAssign(path) {
  window.location.assign(path)
}

export function locationReplace(path) {
  window.location.replace(path)
}

export function historyBack() {
  window.history.back()
}

export function locationReload() {
  window.location.reload()
}

export function setLocalStorageItem(key, value) {
  window.localStorage[key] = value
}

export function getLocalStorageItem(itemName) {
  return window.localStorage.getItem(itemName)
}

export function removeLocalStorageItem(itemName) {
  window.localStorage.removeItem(itemName)
}

export function addListener(eventName, callback) {
  window.addEventListener(eventName, callback)
}

export function removeListener(eventName, callback) {
  window.removeEventListener(eventName, callback)
}

export function callScroll(x, y) {
  window.scrollTo(x, y)
}
