import loadable from "@loadable/component"

// TODO: Centralise fontawesome
// need this to load resources for component-v2
// import "@rentspree/component-v2"

const ProfileLoadable = loadable(() => import("./profile"))

const PageNotFoundLoadable = loadable(() => import("./page-not-found-router"))

const PageTemporaryErrorLoadable = loadable(() =>
  import("../page-temporary-error")
)

const OAuthLoadable = loadable(() => import("../oauth"))

const HandleTokenPageLoadable = loadable(() => import("../page-handle-token"))

const AuthRouterLoadable = loadable(() => import("./auth-router/index"))

const HandleCodePageLoadable = loadable(() =>
  import("../oauth-client/page-handle-code")
)

const OnboardingRouterLoadable = loadable(() =>
  import("../page-onboarding/route")
)

export {
  ProfileLoadable,
  PageNotFoundLoadable,
  PageTemporaryErrorLoadable,
  HandleTokenPageLoadable,
  OAuthLoadable,
  AuthRouterLoadable,
  HandleCodePageLoadable,
  OnboardingRouterLoadable
}
