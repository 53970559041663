import { fromJS } from "immutable"

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED
} from "../page-login/constants"
import {
  SIGNUP_REQUEST,
  SIGNUP_FAILED,
  SIGNUP_SUCCESS
} from "../page-signup/constants"
import {
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  REMOVE_EMAIL_CALL,
  RESEND_FORGET_PASSWORD
} from "../page-forget-password/constants"
import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED
} from "../page-reset-password/constants"
import {
  UPDATE_USER_TYPE_REQUEST,
  UPDATE_USER_TYPE_SUCCESS,
  UPDATE_USER_TYPE_FAILED
} from "../page-select-user-type/constants"
import {
  CLEAR_SUCCESS,
  SET_COMPONENTS_LOADING,
  CLEAR_LOADING
} from "./constants"
import {
  CREATE_PASSWORD_FAILED,
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_SUCCESS
} from "../page-social-password/constants"
import { EDIT_PROFILE_SUCCESS } from "../page-edit-profile/constants"
import {
  GET_PROFILE_FAILED,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_REQUEST_WITH_NO_LOADING_PANEL
} from "../wrapper/constants"
import {
  PARTNER_OAUTH_REQUEST,
  PARTNER_OAUTH_SUCCESS,
  PARTNER_OAUTH_FAILED,
  LINK_ACCOUNT_REQUEST,
  LINK_ACCOUNT_SUCCESS,
  LINK_ACCOUNT_FAILED
} from "../oauth/sso/constants"
import { ERROR_SHOW } from "../error/constants"

import {
  FORCE_CHANGE_PASSWORD_REQUEST,
  FORCE_CHANGE_PASSWORD_SUCCESS,
  FORCE_CHANGE_PASSWORD_FAILED
} from "../page-create-password/constants"
import {
  ACCEPT_TERM_OF_USE_REQUEST,
  ACCEPT_TERM_OF_USE_SUCCESS,
  ACCEPT_TERM_OF_USE_FAILED
} from "../page-finish-signup/constants"
import {
  RESEND_EMAIL_VERIFICATION_REQUEST,
  RESEND_EMAIL_VERIFICATION_SUCCESS,
  RESEND_EMAIL_VERIFICATION_FAILED
} from "../page-email-verification/constants"

export const initialState = fromJS({
  panel: false,
  components: false,
  success: false
})

function loadingReducer(state = initialState, action) {
  let nextState = null
  switch (action.type) {
    case SIGNUP_REQUEST:
    case LOGIN_REQUEST:
    case RESET_PASSWORD_REQUEST:
      // this is a login loading reducer
      if (action.payload.grant_type === "password") {
        nextState = initialState.set("components", true)
        return nextState
      }
      nextState = state.set("panel", true)
      return nextState
    // set full panel loading
    case FORGET_PASSWORD_REQUEST:
    case UPDATE_USER_TYPE_REQUEST:
    case LINK_ACCOUNT_REQUEST:
      // this is for the whole panel
      nextState = initialState.set("panel", true)
      return nextState
    // set components loading
    case CREATE_PASSWORD_REQUEST:
    case SET_COMPONENTS_LOADING:
    case FORCE_CHANGE_PASSWORD_REQUEST:
    case ACCEPT_TERM_OF_USE_REQUEST:
    case RESEND_EMAIL_VERIFICATION_REQUEST:
      nextState = initialState.set("components", true)
      return nextState
    // set finished state
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
    case FORGET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case UPDATE_USER_TYPE_SUCCESS:
    case CREATE_PASSWORD_SUCCESS:
    case EDIT_PROFILE_SUCCESS:
    case PARTNER_OAUTH_SUCCESS:
    case LINK_ACCOUNT_SUCCESS:
    case FORCE_CHANGE_PASSWORD_SUCCESS:
    case ACCEPT_TERM_OF_USE_SUCCESS:
    case RESEND_EMAIL_VERIFICATION_SUCCESS:
      nextState = initialState.set("success", true)
      return nextState
    case ERROR_SHOW:
      if (action?.payload?.success) {
        nextState = initialState.set("success", true)
        return nextState
      }
      return initialState

    // return to initial state
    case FORCE_CHANGE_PASSWORD_FAILED:
    case SIGNUP_FAILED:
    case LOGIN_FAILED:
    case FORGET_PASSWORD_FAILED:
    case REMOVE_EMAIL_CALL:
    case RESET_PASSWORD_FAILED:
    case UPDATE_USER_TYPE_FAILED:
    case CREATE_PASSWORD_FAILED:
    case GET_PROFILE_FAILED:
    case PARTNER_OAUTH_FAILED:
    case LINK_ACCOUNT_FAILED:
    case CLEAR_LOADING:
    case ACCEPT_TERM_OF_USE_FAILED:
    case RESEND_EMAIL_VERIFICATION_FAILED:
      return initialState
    // clear finished state
    case CLEAR_SUCCESS:
    case RESEND_FORGET_PASSWORD:
      nextState = initialState.set("success", false)
      return nextState
    // clear panel loading
    case GET_PROFILE_SUCCESS:
      nextState = state.set("panel", false)
      return nextState
    // set panel loading
    case GET_PROFILE_REQUEST:
    case PARTNER_OAUTH_REQUEST:
      nextState = state.set("panel", true)
      return nextState
    case GET_PROFILE_REQUEST_WITH_NO_LOADING_PANEL:
    default:
      return state
  }
}

export default loadingReducer
