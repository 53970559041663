export const COUNTDOWN_SECONDS = 30
export const CHANNEL = "SIGNUP"

export const RESEND_EMAIL_VERIFICATION_CALLED =
  "RESEND_EMAIL_VERIFICATION_CALLED"
export const RESEND_EMAIL_VERIFICATION_REQUEST =
  "RESEND_EMAIL_VERIFICATION_REQUEST"
export const RESEND_EMAIL_VERIFICATION_SUCCESS =
  "RESEND_EMAIL_VERIFICATION_SUCCESS"
export const RESEND_EMAIL_VERIFICATION_FAILED =
  "RESEND_EMAIL_VERIFICATION_FAILED"

export const EMAIL_VERIFICATION_RESEND_RENEW_CALLED =
  "EMAIL_VERIFICATION_RESEND_RENEW_CALLED"
export const EMAIL_VERIFICATION_RESEND_RENEW_REQUEST =
  "EMAIL_VERIFICATION_RESEND_RENEW_REQUEST"
export const EMAIL_VERIFICATION_RESEND_RENEW_SUCCESS =
  "EMAIL_VERIFICATION_RESEND_RENEW_SUCCESS"
export const EMAIL_VERIFICATION_RESEND_RENEW_FAILED =
  "EMAIL_VERIFICATION_RESEND_RENEW_FAILED"

export const EMAIL_VERIFICATION_HAS_BEEN_VERIFIED_ERROR =
  "email_verification_has_been_verified_error"

export const resendEmailVerificationCall = payload => ({
  type: RESEND_EMAIL_VERIFICATION_CALLED,
  payload
})
export const resendEmailVerificationRequest = () => ({
  type: RESEND_EMAIL_VERIFICATION_REQUEST
})
export const resendEmailVerificationSuccess = () => ({
  type: RESEND_EMAIL_VERIFICATION_SUCCESS
})
export const resendEmailVerificationFailed = payload => ({
  type: RESEND_EMAIL_VERIFICATION_FAILED,
  payload
})

export const emailVerificationResendRenewCall = payload => ({
  type: EMAIL_VERIFICATION_RESEND_RENEW_CALLED,
  payload
})
export const emailVerificationResendRenewRequest = () => ({
  type: EMAIL_VERIFICATION_RESEND_RENEW_REQUEST
})
export const emailVerificationResendRenewSuccess = () => ({
  type: EMAIL_VERIFICATION_RESEND_RENEW_SUCCESS
})
export const emailVerificationResendRenewFailed = payload => ({
  type: EMAIL_VERIFICATION_RESEND_RENEW_FAILED,
  payload
})
