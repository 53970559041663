import find from "lodash/find"
import isEmpty from "lodash/isEmpty"
import isNil from "lodash/isNil"
import { LOCAL_STORAGE } from "../constants/local-storage"
import { LISTING_CHANGE_SUBDOMAIN, LISTING_PARTNER } from "./partner-consts"
import { parse, urlParser } from "./query-parser"

export const isListingPartner = subdomain =>
  Object.values(LISTING_PARTNER).includes(subdomain)

export const getSubdomain = (redirectPath, checkOnlySearch = false) => {
  const localContinue = parse(localStorage.getItem(LOCAL_STORAGE.QUERY_PARAMS))
  const referenceContinue =
    localContinue.continue && !checkOnlySearch
      ? localContinue.continue
      : redirectPath
  const { subdomain } = urlParser(referenceContinue || "")
  if (
    isNil(referenceContinue) ||
    isEmpty(referenceContinue) ||
    !subdomain ||
    subdomain === "www"
  )
    return "rentspree"
  const replaceDomain = find(LISTING_CHANGE_SUBDOMAIN, ["from", subdomain])
  if (replaceDomain) return replaceDomain.to
  return isNil(subdomain) || isEmpty(subdomain) ? "rentspree" : subdomain
}
