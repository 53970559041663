import { fromJS } from "immutable"
import uniq from "lodash/uniq"
import {
  GET_SSO_CONFIGS_FAILED,
  GET_SSO_CONFIGS_REQUEST,
  GET_SSO_CONFIGS_SUCCESS
} from "./constants"

export const SSOConfigsInitState = fromJS({
  isReady: false,
  isError: false,
  list: [],
  subdomains: []
})

function SSOConfigsReducer(state = SSOConfigsInitState, action) {
  let nextState
  switch (action.type) {
    case GET_SSO_CONFIGS_REQUEST:
      nextState = state.merge({
        isReady: false,
        isError: false
      })
      return nextState
    case GET_SSO_CONFIGS_SUCCESS:
      nextState = state.merge({
        isReady: true,
        isError: false,
        list: action.payload,
        subdomains: uniq(action.payload.map(sso => sso.subdomain))
      })
      return nextState
    case GET_SSO_CONFIGS_FAILED:
      nextState = state.merge({
        isReady: true,
        isError: true
      })
      return nextState
    default:
      return state
  }
}
export default SSOConfigsReducer
