import React from "react"
import { defineMessages } from "react-intl"
import { Span } from "components/typography/text-styled"
import { Link } from "components/buttons"
import { ROUTE } from "containers/router/constants"

export default defineMessages({
  required: {
    id: "required",
    defaultMessage: "Required"
  },
  passwordMismatch: {
    id: "passwordMismatch",
    defaultMessage: "Password mismatch"
  },
  passwordMin8: {
    id: "passwordMin8",
    defaultMessage: "Password must be at least 8 characters"
  },
  passwordMax: {
    id: "passwordMax",
    defaultMessage: "Password must not exceed {max} characters"
  },
  passwordNoSpacesOnly: {
    id: "passwordNoSpacesOnly",
    defaultMessage: "Password must not consist of only spaces"
  },
  editProfileSuccess: {
    id: "editProfileSuccess",
    defaultMessage: "Your info has been saved successfully."
  },
  editProfileFailed: {
    id: "editProfileFailed",
    defaultMessage:
      "Sorry, we are having trouble updating your profile. Please refresh the page and try again."
  },
  editMailingAddrFailed: {
    id: "editMailingAddrFailed",
    defaultMessage:
      "We've encountered an error saving your info. Please check all fields and try again."
  },
  changePwdSuccess: {
    id: "changePassword",
    defaultMessage: "Your password has been changed successfully."
  },
  changePwdFailed: {
    id: "changePwdFailed",
    defaultMessage:
      "Sorry, we are having trouble updating your password. Please refresh the page and try again."
  },
  tuEmailLength: {
    id: "emailLength",
    defaultMessage: "Email should be between 0 and 50 characters"
  },
  invalidEmail: {
    id: "invalidEmail",
    defaultMessage: "Invalid email address"
  },
  invalidEmailChar: {
    id: "invalidEmailChar",
    defaultMessage: "Email contains invalid character"
  },
  noPlusSignInEmail: {
    id: "noPlusSignInEmail",
    defaultMessage: "Email cannot contain a '+' sign."
  },
  incorrectEmail: {
    id: "incorrectEmail",
    defaultMessage: "Incorrect email address, please try again."
  },
  minChar: {
    id: "minChar",
    defaultMessage: "Must contains at least {min} characters",
    values: { min: 2 }
  },
  minThreeLetters: {
    id: "minThreeLetters",
    defaultMessage: "Must contains at least {min} letters",
    values: { min: 3 }
  },
  maxChar: {
    id: "maxChar",
    defaultMessage: "Must not exceed {max} characters",
    values: { max: 35 }
  },
  invalidPhone: {
    id: "invalidPhone",
    defaultMessage: "Invalid phone number"
  },
  invalidZipCode: {
    id: "invalidZipCode",
    defaultMessage: "Allow only 5 digit number"
  },
  invalidState: {
    id: "invalidState",
    defaultMessage: "Invalid state code"
  },
  invalidCharacterTuProperty: {
    id: "characterTuPropertyError",
    defaultMessage: "Character {invalidCharacter} is invalid",
    value: { invalidCharacter: "" }
  },
  hasNumber: {
    id: "hasNumber",
    defaultMessage: "Must contains at least 1 number"
  },
  hasLetter: {
    id: "hasLetter",
    defaultMessage: "Must contains at least 1 letter"
  },
  linkAccountError: {
    id: "linkAccountError",
    defaultMessage:
      "We’ve encountered an error logging you in. Please try again."
  },
  linkPartnerAccountError: {
    id: "linkPartnerAccountError",
    values: {
      breakingLine: <br />
    },
    defaultMessage: `Sorry, we have trouble linking the accounts. Please try again.{breakingLine} If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com.`
  },
  linkPartnerAccountOAuthStateError: {
    id: "linkPartnerAccountOAuthStateError",
    values: {
      breakingLine: <br />,
      link: (
        <Link
          href={ROUTE.CONTACT_US}
          align="left"
          color="#F15555"
          hovercolor="#F15555"
          opacity="0.75"
          hoverOpacity="1"
          target="_blank">
          <Span bold underline>
            Contact us
          </Span>
        </Link>
      )
    },
    defaultMessage: `We couldn't link your account. Please try again.{breakingLine} {link} if this message continues.`
  },
  acceptTerm: {
    id: "acceptTermError",
    defaultMessage: "Please check accept terms."
  },
  invalidSSN: {
    id: "invalidSSN",
    defaultMessage: "Must contain 4 digits"
  },
  invalidFullSSN: {
    id: "invalidFullSSN",
    defaultMessage: "Must contain 9 digits"
  },
  incorrectAddress: {
    id: "incorrectAddress",
    defaultMessage:
      "Some of your information doesn’t match your profile, please recheck your information and personal home address"
  },
  incorrectQuestion: {
    id: "incorrectQuestion",
    defaultMessage: "One or more of your answers were incorrect."
  },
  incorrectOTP: {
    id: "incorrectOTP",
    defaultMessage: "Your verification code is incorrect. Please try again."
  },
  unlinkAccountSuccess: {
    id: "unlinkAccountSuccess",
    values: {
      partner: ""
    },
    defaultMessage:
      "Congratulations! Your {partner} account was successfully unlinked."
  },
  unlinkAccountFailed: {
    id: "unlinkAccountFailed",
    values: {
      breakingLine: <br />
    },
    defaultMessage:
      "Sorry, we have trouble unlinking the accounts. Please try again.{breakingLine} If the problem persists, please contact us at (323) 515-7757 or support@rentspree.com."
  },
  alphanumeric: {
    id: "alphanumeric",
    defaultMessage: "This field only allows letters and numbers"
  },
  streetAddress: {
    id: "streetAddress",
    defaultMessage: "Must match the following: a-z A-Z 0-9 #()&,.-_'~/*+"
  }
})

export const ERROR_CODE = {
  PASSWORD_WAS_CHANGE: "password_was_changed",
  ACCOUNT_ALREADY_LINKED: "linked",
  EMAIL_NOT_EXISTS: "email_not_exists"
}

export const ERROR_UNAUTHORIZED_CODE = defineMessages({
  [ERROR_CODE.PASSWORD_WAS_CHANGE]: {
    id: "passwordWasChanged",
    defaultMessage: "Your password was changed {timePassed} ago.",
    value: { timePassed: "" }
  }
})

export const ERROR_VALIDATION_CODE = defineMessages({
  same_current_password: {
    id: "sameCurrentPwd",
    defaultMessage:
      "Your new password must be different from the current password."
  },
  same_previous_password: {
    id: "samePreviousPwd",
    defaultMessage:
      "Your new password can’t be the same as one of your last 4 passwords."
  },
  wrong_current_pwd: {
    id: "wrongCurrentPwd",
    defaultMessage: "The current password is incorrect."
  },
  account_exists: {
    id: "accountExists",
    defaultMessage: "An account with this email already exists."
  }
})

export const ERROR_NAME = {
  USER_EXISTS: "user_exists"
}
