import React from "react"

import { StyledButton, ButtonIcon, SocialButtonIcon } from "./buttons-styled"
const GOOGLE_ICON = require("images/icon/google-icon.png")
const FACEBOOK_ICON = require("images/icon/facebook-icon.png")

export const Button = ({ text, children, ...rest }) => (
  <StyledButton {...rest}>
    {text && text}
    {children && children}
  </StyledButton>
)

export const SocialButton = ({
  text,
  children,
  loading,
  loaded,
  onClick,
  ...props
}) => {
  if (loaded) {
    return <FinishedButton {...props} />
  }
  return (
    <StyledButton {...props} onClick={onClick}>
      {text && text}
      {children && children}
    </StyledButton>
  )
}

export const ProcessingButton = ({ text, children, ...props }) => (
  <StyledButton {...props} disabled blueGradientHovered>
    <ButtonIcon className="fas fa-circle-notch fa-spin" />
  </StyledButton>
)

export const FinishedButton = ({ ...props }) => (
  <StyledButton {...props} disabled greenGradient>
    <ButtonIcon className="fas fa-check" />
  </StyledButton>
)

export const FacebookBtnIcon = props => (
  <SocialButtonIcon facebook {...props}>
    <img src={FACEBOOK_ICON} style={{ width: "20px" }} alt="facebook icon" />
  </SocialButtonIcon>
)

export const GoogleBtnIcon = props => (
  <SocialButtonIcon google {...props}>
    <img src={GOOGLE_ICON} style={{ width: "20px" }} alt="google icon" />
  </SocialButtonIcon>
)

export const ButtonWithCustomIcon = ({
  children,
  iconSrc,
  onClick,
  isMobileWidth,
  ...rest
}) => {
  const heightText = isMobileWidth ? "40px" : "48px"

  return (
    <Button
      style={{
        height: heightText
      }}
      large
      emailBtn
      {...rest}
      onClick={onClick}>
      {children && children}
    </Button>
  )
}
