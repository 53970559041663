export const SSO_CALL = "SSO_CALL"
export const LINK_ACCOUNT_CALL = "LINK_ACCOUNT_CALL"
export const LINK_ACCOUNT_REQUEST = "LINK_ACCOUNT_REQUEST"
export const LINK_ACCOUNT_SUCCESS = "LINK_ACCOUNT_SUCCESS"
export const LINK_ACCOUNT_FAILED = "LINK_ACCOUNT_FAILED"
export const LINK_ACCOUNT_CLEAR = "LINK_ACCOUNT_CLEAR"

export const PARTNER_OAUTH_CALL = "PARTNER_OAUTH_CALL"
export const PARTNER_OAUTH_REQUEST = "PARTNER_OAUTH_REQUEST"
export const PARTNER_OAUTH_SUCCESS = "PARTNER_OAUTH_SUCCESS"
export const PARTNER_OAUTH_FAILED = "PARTNER_OAUTH_FAILED"

export const ssoCall = payload => ({ type: SSO_CALL, payload })
export const linkAccountCall = payload => ({ type: LINK_ACCOUNT_CALL, payload })
export const linkAccountRequest = payload => ({
  type: LINK_ACCOUNT_REQUEST,
  payload
})
export const linkAccountSuccess = payload => ({
  type: LINK_ACCOUNT_SUCCESS,
  payload
})
export const linkAccountFailed = payload => ({
  type: LINK_ACCOUNT_FAILED,
  payload
})
export const linkAccountClear = () => ({
  type: LINK_ACCOUNT_CLEAR
})

export const partnerOAuthCall = payload => ({
  type: PARTNER_OAUTH_CALL,
  payload
})
export const partnerOAuthRequest = () => ({ type: PARTNER_OAUTH_REQUEST })
export const partnerOAuthSuccess = () => ({ type: PARTNER_OAUTH_SUCCESS })
export const partnerOAuthFailed = () => ({ type: PARTNER_OAUTH_FAILED })
