import React from "react"
import { AuthWebNavbar } from "@rentspree/navbar"
import styled from "styled-components"
import { parse } from "utils/query-parser"
import { getSubdomain } from "utils/subdomain"
import { getPartnerLogoAuth } from "utils/sso-utils"
import { resizeWithImageProxy } from "utils/image"

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    display: none;
  }
`

export const LogoHeader = ({ className, query }) => {
  const subdomain = getSubdomain(parse(query).continue)
  const partnerLogo = getPartnerLogoAuth(subdomain)
  const resizedPartnerLogo = resizeWithImageProxy(partnerLogo, { height: 80 })
  return (
    <AuthWebNavbar partnerLogoSrc={resizedPartnerLogo} className={className} />
  )
}
