import find from "lodash/find"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

import {
  ACCOUNT_LINKING_ZIPLOGIX,
  ACCOUNT_LINKING_CAR,
  PARTNER_DISPLAY_NAME
} from "../containers/page-connect-account/constants"
import { getCDNImage, resizeWithImageProxy } from "./image"
import { SSO_FEATURE_ID } from "./feature-toggle/constants"
import disableFeature from "./feature-toggle/instance"

export const showPartnerButton = (subdomain, ssoDetails) =>
  ssoDetails && !!find(ssoDetails, ["subdomain", subdomain])

export const getPartnerLogoAuth = subdomain =>
  subdomain && subdomain.length && subdomain !== "rentspree"
    ? getCDNImage(`/logos/${subdomain}-nav-logo.png`)
    : ""

export const getPartnerLogoDashboard = subdomain =>
  subdomain && subdomain.length && subdomain !== "rentspree"
    ? getCDNImage(`/logos/${subdomain}-logo.png`)
    : ""

export const getPartnerLogo = (subdomain, ssoDetails) =>
  showPartnerButton(subdomain, ssoDetails) ? getPartnerLogoAuth(subdomain) : ""

export const isSSOEnable = subdomain => {
  const abilityInstance = disableFeature.getSubdomainPermission(subdomain)
  return abilityInstance.can("use", SSO_FEATURE_ID)
}

export const ssoLinkingList = (ssoList, partnerConnects) =>
  [
    ...ssoList.filter(item => get(item, "accountLinking")).map(item => {
      const { subdomain, name, accountLinking } = item
      const partnerLogo = getPartnerLogo(subdomain, ssoList)
      const { imgHeight = "50px" } = item.accountLinking
      // Double image height for quality (not blur)
      const doubleImgHeight = parseInt(imgHeight.replace(/\D/g, ""), 10) * 2
      const imgSrc = resizeWithImageProxy(partnerLogo, {
        height: doubleImgHeight
      })

      return {
        id: subdomain,
        provider: subdomain,
        imgSrc,
        imgAlt: name,
        name,
        ...accountLinking
      }
    }),
    {
      ...ACCOUNT_LINKING_CAR,
      name: "California Association of Realtors",
      imgSrc: resizeWithImageProxy(getPartnerLogoAuth("car"), {
        height: 100
      })
    },
    {
      ...ACCOUNT_LINKING_ZIPLOGIX,
      name: PARTNER_DISPLAY_NAME.ziplogix,
      tags: [
        "zipform",
        "ziplogix",
        "lonewolf",
        "lone wolf",
        "transaction desk"
      ],
      imgSrc: resizeWithImageProxy(getPartnerLogoAuth("ziplogix"), {
        height: 80
      })
    }
  ].map(data => {
    let integrationData = {}
    if (data.provider === "car") {
      integrationData = get(partnerConnects, `${data.provider}.partnerId`, "")
    } else {
      integrationData = get(partnerConnects, data.provider, {})
    }

    if (!isEmpty(integrationData)) {
      return {
        ...data,
        [data.showBy]: integrationData[data.showBy],
        isConnected: true
      }
    }
    return {
      ...data,
      isConnected: false
    }
  })
