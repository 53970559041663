/*
*
* Error constants
*
*/

export const ERROR_CLOSE = "ERROR_CLOSE"
export const ERROR_SHOW = "ERROR_SHOW"

export const errorClose = () => ({ type: ERROR_CLOSE })
export const errorShow = payload => ({ type: ERROR_SHOW, payload })

export const SENTRY_ERROR_NAME = {
  ACCEPT_TERM: "Error Accept Terms",
  MAILING_ADDRESS: "Error Update Mailing Address",
  LOGIN: "Error Login",
  LINK_ACCOUNT: "Error link account",
  OAUTH_STATE_VALIDATION: "Error OAuth State Validation",
  OAUTH_STATE_NOT_SUPPORT: "Error OAuth State Not Support"
}

export const ERROR_TYPE = {
  GENERAL_ERROR: "GENERAL_ERROR",
  OAUTH_STATE_ERROR: "OAUTH_STATE_ERROR"
}
