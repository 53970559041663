/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import "@babel/polyfill"
// Import all the third party stuff
import { datadogRum } from "@datadog/browser-rum"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router/immutable"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { QueryClient, QueryClientProvider } from "react-query"

// Import root app
import Router from "containers/router"

// Import Language Provider
import LanguageProvider from "containers/language-provider"

/* eslint-disable import/no-unresolved, import/extensions */
import "file-loader?name=[name].[ext]!./.htaccess"
/* eslint-enable import/no-unresolved, import/extensions */

// Import setOption for correctly remove cookies
import { setOption } from "@rentspree/cookie"
import { DisableFeatureProvider } from "@rentspree/feature-toggle"
import { parse } from "utils/query-parser"
import { COOKIE_OPTION } from "constants/cookie"
import "utils/feedback-collection/instance"

import { ThemeProvider, createTheme } from "@mui/material/styles"
import { muiTheme } from "@rentspree/component-2023.theme.mui"

// Import i18n messages
import { translationMessages } from "./i18n"
import "./styles/sanitize.css"

import {
  API_URL,
  CI_COMMIT_SHA,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_REPLAY_SAMPLE_RATE,
  DATADOG_SAMPLE_RATE,
  DATADOG_SITE,
  DATADOG_TRACE_SAMPLE_RATE,
  DATADOG_VERSION,
  ENVIRONMENT,
  FILES_URL,
  GOOGLE_CLIENT_ID,
  PARTNER_ADMIN_URL,
  SENTRY_DSN,
  USER_API_URL
} from "./env"

import packageJson from "../package.json"
import history from "./utils/history"

import { store } from "./store"
import disableFeature from "./utils/feature-toggle/instance"
import { getSubdomain } from "./utils/subdomain"
import Toast from "./containers/toast"
import { FeatureFlagProvider } from "./containers/feature-flag/feature-flag-provider"
import { AuthProvider } from "./containers/auth-provider/auth-provider"

const MOUNT_NODE = document.getElementById("app")

// Set option preparing to remove cookies
setOption(COOKIE_OPTION)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    },
    mutations: {
      retry: false
    }
  }
})

if (ENVIRONMENT) {
  import("@sentry/browser").then(Sentry =>
    Sentry.init({
      dsn: SENTRY_DSN,
      release: ENVIRONMENT === "Dev" ? CI_COMMIT_SHA : DATADOG_VERSION,
      environment: ENVIRONMENT
    })
  )
}

// Initiate DataDog RUM
if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN && DATADOG_SITE) {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: packageJson.name,
    env: ENVIRONMENT,
    version: DATADOG_VERSION,
    sessionSampleRate: DATADOG_SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG_REPLAY_SAMPLE_RATE,
    traceSampleRate: DATADOG_TRACE_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      { match: API_URL, propagatorTypes: ["b3", "b3multi", "tracecontext"] },
      { match: FILES_URL, propagatorTypes: ["b3", "b3multi", "tracecontext"] },
      {
        match: USER_API_URL,
        propagatorTypes: ["b3", "b3multi", "tracecontext"]
      },
      {
        match: PARTNER_ADMIN_URL,
        propagatorTypes: ["b3", "b3multi", "tracecontext"]
      }
    ],
    excludedActivityUrls: [
      /^https:\/\/api\.amplitude\.com/,
      /^https:\/\/analytics\.google\.com/,
      /^https:\/\/.*\.clarity\.ms\/collect/,
      /^https:\/\/settings\.luckyorange\.net/
    ],
    enableExperimentalFeatures: ["feature_flags"]
  })
}

const theme = createTheme(muiTheme)

const render = messages => {
  const url = parse(history.location.search).continue || null
  const subdomain = getSubdomain(url)

  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <QueryClientProvider client={queryClient}>
          <ConnectedRouter history={history}>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <FeatureFlagProvider>
                <AuthProvider>
                  <DisableFeatureProvider
                    subdomain={subdomain}
                    disableFeature={disableFeature}>
                    <ThemeProvider theme={theme}>
                      <Toast />
                      <Router history={history} subdomain={subdomain} />
                    </ThemeProvider>
                  </DisableFeatureProvider>
                </AuthProvider>
              </FeatureFlagProvider>
            </GoogleOAuthProvider>
          </ConnectedRouter>
        </QueryClientProvider>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./i18n", "containers/router"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import("intl"))
  })
    .then(() => Promise.all([import("intl/locale-data/jsonp/en.js")]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}
