import { useContext } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Auth0Client, CacheKey } from "@auth0/auth0-spa-js"
import { logger } from "utils/logger"
import { REVOKE_REFRESH_TOKEN } from "constants/api-url"
import { authApiInstance } from "utils/axios-utils"
import AuthContext from "../../containers/auth-provider/auth.context"
import { ROUTE } from "../../containers/router/constants"
import { useSsoConfigs } from "./use-sso-config"
import {
  AUTH_APP_CLIENT_ID,
  AUTH_PROVIDER_DOMAIN,
  AUTH_API_IDENTIFIER,
  AUTH_APP_SCOPE
} from "../../env"

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  const {
    loginWithRedirect,
    logout,
    user,
    loginWithPopup,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently
  } = useAuth0()

  const { getSsoConfig } = useSsoConfigs()

  const loginPartnerWithPopup = async ({ provider }) => {
    const ssoConfig = getSsoConfig({ provider })

    const auth0Client = new Auth0Client({
      domain: AUTH_PROVIDER_DOMAIN,
      clientId: AUTH_APP_CLIENT_ID
    })

    await auth0Client.loginWithPopup({
      authorizationParams: {
        max_age: 0,
        scope: "openid",
        rspLoginForLink: true,
        connection: ssoConfig.auth0.connection,
        organization: ssoConfig.auth0.organizationId
      }
    })

    return auth0Client.getIdTokenClaims()
  }

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently()
    } catch (error) {
      if (error.error === "invalid_grant") {
        logger.info(error)
      } else {
        logger.error(error)
      }
      window.location.replace(
        `${window.location.origin}${ROUTE.SESSION_TIMEOUT}`
      )
      return null
    }
  }

  const revokeRefreshToken = async () => {
    const authTokenKey = new CacheKey({
      clientId: AUTH_APP_CLIENT_ID,
      audience: AUTH_API_IDENTIFIER,
      scope: AUTH_APP_SCOPE
    }).toKey()
    const refreshToken = JSON.parse(localStorage.getItem(authTokenKey))?.body
      ?.refresh_token

    if (refreshToken) {
      try {
        await authApiInstance.post(REVOKE_REFRESH_TOKEN, {
          client_id: AUTH_APP_CLIENT_ID,
          token: refreshToken
        })
      } catch (error) {
        logger.error(error)
      }
    }
  }

  return {
    loginWithRedirect,
    logout,
    user,
    loginWithPopup,
    isAuthenticated,
    isLoading,
    loginPartnerWithPopup,
    getAccessToken,
    revokeRefreshToken
  }
}
