export const UPDATE_USER_TYPE_CALL = "UPDATE_USER_TYPE_CALL"
export const UPDATE_USER_TYPE_REQUEST = "UPDATE_USER_TYPE_REQUEST"
export const UPDATE_USER_TYPE_SUCCESS = "UPDATE_USER_TYPE_SUCCESS"
export const UPDATE_USER_TYPE_FAILED = "UPDATE_USER_TYPE_FAILED"

export const updateUserTypeCall = payload => ({
  type: UPDATE_USER_TYPE_CALL,
  payload
})
export const updateUserTypeRequest = payload => ({
  type: UPDATE_USER_TYPE_REQUEST,
  payload
})

export const updateUserTypeSuccess = payload => ({
  type: UPDATE_USER_TYPE_SUCCESS,
  payload
})

export const updateUserTypeFailed = payload => ({
  type: UPDATE_USER_TYPE_FAILED,
  payload
})

export const PROFILE_USER_TYPE = {
  LANDLORD: "landlord",
  RENTER: "renter"
}

export const USER_TYPE = {
  AGENT: "agent",
  OWNER: "owner",
  MANAGER: "manager",
  APPLICANT: "applicant"
}

export const userTypeList = [
  {
    type: "agent",
    title: "An Agent",
    description: "I’m representing owners or applicants."
  },
  {
    type: "owner",
    title: "An Owner",
    description: "I’m renting out a unit I own."
  },
  {
    type: "manager",
    title: "A Manager",
    description: "I’m managing units."
  },
  {
    type: "applicant",
    title: "An Applicant",
    description: "I’m looking to rent a place."
  }
]
