import { createSelector } from "reselect"
import { initialState } from "./reducer"

export const selectError = state => state.get("error", initialState)

export const makeSelectError = () =>
  createSelector(selectError, state => {
    if (state) {
      return state.toJS()
    }
    return null
  })
