import { ROUTE } from "containers/router/constants"
export const SOURCE = {
  EDIT_PROFILE: "EDIT_PROFILE",
  ONBOARDING_EDIT_PROFILE: "ONBOARDING_EDIT_PROFILE",
  EDIT_MAILING_ADDRESS: "EDIT_MAILING_ADDRESS",
  LOGIN: "LOGIN",
  SSO_LOGIN: "SSO_LOGIN",
  LINK_ACCOUNT: "LINK_ACCOUNT"
}

export const pathErrorWithoutModal = [
  ROUTE.LOGIN,
  `${ROUTE.LOGIN}/email`,
  ROUTE.OAUTH,
  ROUTE.SIGNUP,
  `${ROUTE.OAUTH}/applicant`,
  `${ROUTE.OAUTH}/owner`,
  `${ROUTE.OAUTH}/applicant/email`,
  `${ROUTE.OAUTH}/owner/email`,
  `${ROUTE.OAUTH_CLIENT_HANDLE_CODE}`
]
