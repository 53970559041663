import React from "react"
import { SET_COMPONENTS_LOADING } from "containers/loading/constants"
import { trackEvent } from "tracker/tracker"
const { COLOR, RSP_FONT } = require("styles/settings")

const { ZIPLOGIX_HOW_TO_LINK_URL } = require("../../env")

export const UNLINK_PARTNER_ACCOUNT_CALL = "UNLINK_PARTNER_ACCOUNT_CALL"
export const UNLINK_PARTNER_ACCOUNT_SUCCESS = "UNLINK_PARTNER_ACCOUNT_SUCCESS"

export const SHOW_VALIDATE_CAR_MEMBER_MODAL = "SHOW_VALIDATE_CAR_MEMBER_MODAL"

export const LINKING_ACCOUNT_WITHOUT_SSO_CALL =
  "LINKING_ACCOUNT_WITHOUT_SSO_CALL"
export const LINKING_ACCOUNT_WITHOUT_SSO_REQUEST =
  "LINKING_ACCOUNT_WITHOUT_SSO_REQUEST"
export const LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS =
  "LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS"
export const LINKING_ACCOUNT_WITHOUT_SSO_FAILED =
  "LINKING_ACCOUNT_WITHOUT_SSO_FAILED"

export const CLOSE_ERROR_IN_MODAL = "CLOSE_ERROR_IN_MODAL"

export const AUTO_SHOW_LINK_ACCOUNT_MODAL_CALL =
  "AUTO_SHOW_LINK_ACCOUNT_MODAL_CALL"

export const LOGIN_POPUP_ERROR = {
  OAUTH_ERROR: "OAUTH_ERROR",
  GENERIC_ERROR: "ERROR",
  UNKNOWN_ERROR: "UNKNOWN_ERROR"
}

export const showValidateCARMemberModalCall = payload => ({
  type: SHOW_VALIDATE_CAR_MEMBER_MODAL,
  payload
})

export const linkAccountWithoutSSOCall = payload => ({
  type: LINKING_ACCOUNT_WITHOUT_SSO_CALL,
  payload
})
export const linkAccountWithoutSSORequest = payload => ({
  type: LINKING_ACCOUNT_WITHOUT_SSO_REQUEST,
  payload
})
export const linkAccountWithoutSSOSuccess = payload => ({
  type: LINKING_ACCOUNT_WITHOUT_SSO_SUCCESS,
  payload
})
export const linkAccountWithoutSSOFailed = payload => ({
  type: LINKING_ACCOUNT_WITHOUT_SSO_FAILED,
  payload
})

export const unlinkPartnerAccountCall = payload => ({
  type: UNLINK_PARTNER_ACCOUNT_CALL,
  payload
})
export const unlinkPartnerAccountSuccess = payload => ({
  type: UNLINK_PARTNER_ACCOUNT_SUCCESS,
  payload
})

export const unlinkPartnerAccountRequest = () => ({
  type: SET_COMPONENTS_LOADING
})
export const closeErrorInModalCall = () => ({
  type: CLOSE_ERROR_IN_MODAL
})

export const openZiplogixHowToLinkPage = () => () => {
  trackEvent("clickOnHowToLink", {
    partner: "ziplogix"
  })
  window.open(ZIPLOGIX_HOW_TO_LINK_URL, "")
}

export const openValidateCARMember = (_, actions) => () => {
  trackEvent("seeNRDSIdPopup", {
    click_from: "account_linking"
  })
  actions.showValidateCARMemberModal(true)
}

export const ACCOUNT_LINKING_ZIPLOGIX = {
  id: "ziplogix",
  provider: "ziplogix",
  imgHeight: "40px",
  btnDetailOnUnlink: "How to link",
  btnDetailOnLinked: "Unlink",
  showBy: "partnerId",
  padding: "0px",
  showLinkWithMessage: false,
  canUnlink: true,
  handleOnClick: openZiplogixHowToLinkPage,
  unlinkTitle: "Unlinking with zipForm®️ Plus will cancel some benefits:",
  unlinkDescription: (
    <div>
      <span style={{ color: COLOR.textGrey }}>
        1. You will no longer be able to access the C.A.R. form through
        RentSpree. <br />
        2. Your property will no longer appear on the zipForm®️ Plus dashboard.
        <br />
        <br />
        <span
          role="img"
          aria-label="zipForm"
          style={{ fontWeight: RSP_FONT.semiBoldWeight }}>
          Note: You can link to zipForm®️ Plus at any time
        </span>
      </span>
    </div>
  ),
  unlinkCancelButtonText: "Reconsider",
  unlinkConfirmButtonText: "Confirm"
}

export const ACCOUNT_LINKING_CAR = {
  id: "car",
  provider: "car",
  btnDetailOnUnlink: "Link",
  btnDetailOnLinked: "Unlink",
  canUnlink: true,
  unlinkTitle: "Unlinking with C.A.R. will cancel some benefits",
  unlinkDescription: (
    <div>
      <span style={{ color: COLOR.textGrey }}>
        You will no longer be able to access the C.A.R. form through RentSpree.
        <p style={{ margin: "5px 0 0" }}>
          <span
            role="img"
            aria-label="car"
            style={{
              fontSize: RSP_FONT.bodySmallSize,
              fontWeight: RSP_FONT.semiBoldWeight
            }}>
            Note: You can link to C.A.R. at any time
          </span>
        </p>
      </span>
    </div>
  ),
  unlinkCancelButtonText: "Cancel",
  unlinkConfirmButtonText: "Unlink",
  showLinkWithMessage: false,
  showBy: null,
  handleOnClick: openValidateCARMember
}

export const PARTNER_DISPLAY_NAME = {
  ziplogix: "zipForm®️ Plus",
  car: "C.A.R."
}

export const UNLINK_MESSAGE_SUCCESSFUL = {
  car: "Your C.A.R. account was successfully unlinked."
}
export const LINK_MESSAGE_SUCCESSFUL = {
  car: "Your C.A.R. account was successfully linked."
}

export const UNLINK_MESSAGE_ERROR = {
  car: "Sorry, we have encountered an error unlinking your C.A.R. account."
}

export const VALIDATE_CAR_MODAL = {
  TITLE: "Validate C.A.R. membership",
  DESCRIPTION: `This one-time process will grant you access to all
  features available for C.A.R. members such as C.A.R. form`
}

export const ERROR_MESSAGE = {
  NOT_FOUND_AND_INVALID:
    "We could not verify your membership record for the information you entered. Please try again.",
  NRDS_ID_ALREADY_LINKED:
    "Your NRDS ID has already been linked to another account. Retry with another ID or unlink the ID from your other account.",
  GENERAL:
    "Sorry, we have encountered an error linking your C.A.R. account. Please try again."
}

export const CAR_POP_UNDER_MODAL = {
  title: "C.A.R. account successfully linked",
  text: "Click continue to proceed (will open in new tab)",
  button: "Continue"
}

export const CAR_EXIT_URL = "https://www.car.org/rentspreeexit"
