import { NAVBAR_HEIGHT, BREADCRUMBS_HEIGHT } from "styles/settings"

export const createProperty = (key, value) => `${key}: ${value}`

export const calcMinus = (defaultMinus = "0px", configs = {}) => ({
  withNavbar,
  withBreadcrumbs
}) => {
  const pxToMinus = [
    ...(withNavbar ? [configs.navbar || NAVBAR_HEIGHT] : []),
    ...(withBreadcrumbs ? [configs.breadcrumbs || BREADCRUMBS_HEIGHT] : [])
  ]
  if (pxToMinus.length === 0) pxToMinus.push(defaultMinus)
  return pxToMinus.join(" - ")
}
