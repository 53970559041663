export const CREATE_PASSWORD_CALL = "CREATE_PASSWORD_CALL"
export const CREATE_PASSWORD_REQUEST = "CREATE_PASSWORD_REQUEST"
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS"
export const CREATE_PASSWORD_FAILED = "CREATE_PASSWORD_FAILED"

export const createPasswordCall = payload => ({
  type: CREATE_PASSWORD_CALL,
  payload
})
export const createPasswordRequest = payload => ({
  type: CREATE_PASSWORD_REQUEST,
  payload
})
export const createPasswordSuccess = payload => ({
  type: CREATE_PASSWORD_SUCCESS,
  payload
})
export const createPasswordFailed = payload => ({
  type: CREATE_PASSWORD_FAILED,
  payload
})
