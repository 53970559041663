import { fromJS } from "immutable"
import { SET_OAUTH_PROVIDER, CLEAR_OAUTH_PROVIDER } from "./constants"

export const initialState = fromJS({
  OAuthProvider: ""
})

function OAuthClientReducer(state = initialState, action) {
  let nextState
  switch (action.type) {
    case SET_OAUTH_PROVIDER:
      nextState = state.set("OAuthProvider", action.payload)
      return nextState
    case CLEAR_OAUTH_PROVIDER:
      return initialState
    default:
      return state
  }
}

export default OAuthClientReducer
